/*
 *
 * EmailTemplates
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, delayedDispatch, setBreadcrumb, updateCrumb, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { Icon, Table } from "components";
import { Col, Drawer, Input, notification, Row, Select } from "antd";
import Strings from "utils/strings";

import { API, Endpoints } from "utils/api";

class Vehicles extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			vehicles: []
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences"
						},
						{
							text: Strings.settings.vehicles,
							icon: "delivery-truck"
						}
					]
				};
			})
		);

		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.get({
				url: Endpoints.uriVehicles()
			});

			if (response.ok) {
				const { vehicles = [] } = response.data.results || {};
				this.setState({ vehicles });
			} else {
				notification.error({
					message: Strings.settings.vehicles,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveVehicle() {
		const { tempVehicle } = this.state;
		const { dispatch } = this.props;

		if (!tempVehicle) return;

		if (!this.validVehicle()) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			const request = tempVehicle?._id ? API.put : API.post;
			response = await request({
				url: Endpoints.uriVehicles(tempVehicle?._id || ""),
				data: {
					name: tempVehicle.name,
					licensePlate: tempVehicle.licensePlate,
					type: tempVehicle.type,
					capacity: Number(tempVehicle.capacity)
				}
			});

			if (response.ok) {
				await this.getData();
				this.setState({ tempVehicle: null, showDrawer: false });

				notification.success({
					message: Strings.settings.vehicles,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.vehicles,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async patchVehicle(vehicle: any) {
		const { dispatch } = this.props;

		if (!vehicle?._id) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.patch({
				url: Endpoints.uriVehicles(vehicle._id),
				data: {
					isActive: !vehicle.isActive
				}
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.settings.vehicles,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.vehicles,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async deleteVehicle(vehicle: any) {
		const { dispatch } = this.props;

		if (!vehicle) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.delete({
				url: Endpoints.uriVehicles(vehicle)
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.settings.vehicles,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.vehicles,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validVehicle() {
		const { tempVehicle } = this.state;

		if (!tempVehicle?.licensePlate) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.settings.licensePlateRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (tempVehicle?.capacity == null || tempVehicle?.capacity < 0) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.settings.capacityRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempVehicle?.name) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.settings.nameRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!tempVehicle?.type) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.settings.typeRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	getType(type: string) {
		switch (type) {
			case "van":
				return Strings.settings.van;
			case "bike":
				return Strings.settings.bike;
			default:
				return type;
		}
	}

	renderTable() {
		const { vehicles = [] } = this.state;

		return (
			<Table
				title={{
					icon: "delivery-truck",
					title: Strings.settings.vehicles
				}}
				data={vehicles}
				columns={[
					{
						Header: Strings.fields.name,
						id: "vehicle_name",
						accessor: (row: any) => row.name || "-"
					},
					{
						Header: Strings.settings.vehicleType,
						id: "vehicle_type",
						accessor: (row: any) => this.getType(row.type) || "-"
					},
					{
						Header: Strings.settings.licensePlate,
						id: "license_plate",
						accessor: (row: any) => row.licensePlate || "-"
					},
					{
						Header: Strings.settings.capacity,
						id: "vehicle_capacity",
						accessor: (row: any) => row.capacity != null ? `${row.capacity} ${Strings.settings.boxes}` : "-"
					}
				]}
				fullPage
				isSinglePage
				filterable
				sortable
				add={{
					onClick: () => this.setState({ showDrawer: true })
				}}
				actions={{
					edit: (row: any) => ({
						onClick: () => this.setState({ showDrawer: true, tempVehicle: JSON.parse(JSON.stringify(row)) })
					}),
					remove: (row: any) => ({
						onClick: () => this.deleteVehicle(row._id)
					}),
					toggle: (row: any) => ({
						onChange: () => this.patchVehicle(row),
						value: row.isActive
					})
				}}
			/>
		);
	}

	renderDrawer() {
		const { showDrawer, tempVehicle } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="delivery-truck" />
						<p>{tempVehicle?._id ? Strings.settings.editVehicle : Strings.settings.addVehicle}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.saveVehicle()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showDrawer: false,
									tempVehicle: null
								})
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() => this.setState({ tempVehicle: null, showDrawer: false })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { tempVehicle } = this.state;

		return (
			<Row gutter={[0, 10]}>
				<Col xs={24}>
					<label htmlFor="vehicle_name" className="InputLabel --label-required">
						{Strings.fields.name}
					</label>
					<Input
						id="vehicle_name"
						placeholder={Strings.fields.name}
						value={tempVehicle?.name || ""}
						onChange={(e: any) => {
							const value = e.target.value;

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									name: value
								}
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_type" className="InputLabel --label-required">
						{Strings.settings.vehicleType}
					</label>
					<Select
						style={{ width: "100%" }}
						placeholder={Strings.settings.vehicleType}
						showSearch
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						value={tempVehicle?.type || null}
						onChange={(value: any) => {
							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									type: value
								}
							}));
						}}
					>
						<Select.Option value="van">{Strings.settings.van}</Select.Option>
						<Select.Option value="bike">{Strings.settings.bike}</Select.Option>
					</Select>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_license_plate" className="InputLabel --label-required">
						{Strings.settings.licensePlate}
					</label>
					<Input
						id="vehicle_license_plate"
						placeholder={Strings.settings.licensePlate}
						value={tempVehicle?.licensePlate || ""}
						onChange={(e: any) => {
							const value = e.target.value;

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									licensePlate: value
								}
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_capacity" className="InputLabel --label-required">
						{Strings.settings.capacity}
					</label>
					<Input
						id="vehicle_capacity"
						placeholder={Strings.settings.capacity}
						value={tempVehicle?.capacity || ""}
						type="number"
						min={0}
						onChange={(e: any) => {
							const value = e.target.value;

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									capacity: value
								}
							}));
						}}
					/>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.vehicles}</title>
					<meta name="description" content={Strings.settings.vehiclesDescription} />
				</Helmet>
				{this.renderTable()}
				{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile
});

export default connect(mapStateToProps)(Vehicles);
