/*
 *
 * Settings
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setBreadcrumb, setLoader } from "store/actions";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { Row, Col, Modal, DatePicker, Select, notification } from "antd";
import { Icon, ContentWrapper, Icons } from "components";

import Strings from "utils/strings";
import "./styles.scss";
import Dropzone from "react-dropzone";
import moment from "moment";
import { API, Endpoints } from "utils/api";

export class Settings extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			showImportModal: false,
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.settings));
		dispatch(setBreadcrumb(null));
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.settings));
	}

	goTo(url: string) {
		const { dispatch } = this.props;

		dispatch(push(url));
	}

	humanFileSize(bytes: number, si = false, dp = 1) {
		const thresh = si ? 1000 : 1024;

		if (Math.abs(bytes) < thresh) {
			return bytes + " B";
		}

		const units = si
			? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
			: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
		let u = -1;
		const r = 10 ** dp;

		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

		return bytes.toFixed(dp) + " " + units[u];
	}

	getError(error: string) {
		switch (error) {
			case "user_imported_id":
				return Strings.settings.userAlreadyExists;
			case "invalid_postcode":
				return Strings.orders.invalidPostCode;
			case "missing_fields":
				return Strings.orders.missingMandatoryFields;
			case "invalid_date_format":
				return Strings.orders.invalidDateFormat;
			case "invalid_slot":
				return Strings.orders.invalidSlot;
			default:
				return error;
		}
	}

	async uploadFile() {
		const { file } = this.state;
		const { dispatch } = this.props;

		const data = new FormData();
		data.append("files", file);

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.post({
				url: Endpoints.uriOrders("import-master-data"),
				data,
			});

			if (response.ok) {
				const { invalidUsers = [] } = response.data.results || {};
				const showInvalidUsersModal = invalidUsers.length > 0 ? true : false;

				this.setState({ showImportModal: false, file: null, showInvalidUsersModal, invalidUsers });

				notification.success({
					message: Strings.sidebar.settings,
					description: showInvalidUsersModal ? Strings.settings.importedWithErrors : Strings.orders.imported,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.settings,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.sidebar.settings,
				description: response?.data?.message || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	renderOptions() {
		const options = [
			{
				icon: "text-files",
				url: "settings/pages",
				title: Strings.settings.pages,
				subtitle: Strings.settings.pagesDescription,
			},
			{
				icon: "email",
				url: "settings/email-templates",
				title: Strings.settings.emailTemplates,
				subtitle: Strings.settings.emailTemplatesDescription,
			},
			{
				icon: "delivery-truck",
				url: "settings/vehicles",
				title: Strings.settings.vehicles,
				subtitle: Strings.settings.vehiclesDescription,
			},
			{
				icon: "box",
				url: "settings/grids",
				title: Strings.settings.grids,
				subtitle: Strings.settings.gridsDescription,
			},
			{
				icon: "clock1",
				url: "settings/shifts",
				title: Strings.settings.shifts,
				subtitle: Strings.settings.shiftsDescription,
			},
			{
				icon: "text-files",
				url: "settings/contract-types",
				title: Strings.settings.contractTypes,
				subtitle: Strings.settings.contractTypesDescription,
			},
			{
				icon: "upload-file",
				url: "settings",
				title: Strings.settings.uploadMasterData,
				subtitle: Strings.settings.uploadMasterDataSubtitle,
			},
		];

		return options.map((option) => (
			<Col key={option.title} xs={24} lg={12} xl={8}>
				<ContentWrapper extraClass="SettingsCards">
					<div
						className="SettingsOptionBlock"
						onClick={() =>
							option !== options[6] ? this.goTo(option.url) : this.setState({ showImportModal: true })
						}
					>
						<div className="SettingsIcon">
							<Icon name={option.icon} />
						</div>
						<div className="SettingsOptionText">
							<div className="SettingsOptionTitles">
								<span className="SettingsOptionTitle">{option.title}</span>
								<span className="SettingsOptionSubTitle">{option.subtitle}</span>
							</div>
							<div className="SettingsOptionNavigate">
								<span>
									{Strings.formatString(
										Strings.generic.clickHereTo,
										option !== options[6]
											? Strings.generic.edit.toLowerCase()
											: Strings.generic.import.toLowerCase(),
									)}
								</span>
							</div>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		));
	}

	renderIcon() {
		const { clicked } = this.state;

		return (
			<div
				onClick={() => this.setState((state: any) => ({ clicked: !state.clicked }))}
				className={`__animated${clicked ? " __animation" : ""}`}
			>
				<em className="moon-shapes" />
				<div className={`hearts ${clicked ? "__clicked anim1" : ""}`}>
					<em className="moon-shapes" />
				</div>
				<div className={`hearts ${clicked ? "__clicked anim2" : ""}`}>
					<em className="moon-shapes" />
				</div>
				<div className={`hearts ${clicked ? "__clicked anim3" : ""}`}>
					<em className="moon-shapes" />
				</div>
				<div className={`hearts ${clicked ? "__clicked anim4" : ""}`}>
					<em className="moon-shapes" />
				</div>
				<div className={`hearts ${clicked ? "__clicked anim5" : ""}`}>
					<em className="moon-shapes" />
				</div>
				<div className={`hearts ${clicked ? "__clicked anim6" : ""}`}>
					<em className="moon-shapes" />
				</div>
				<div className={`hearts ${clicked ? "__clicked anim7" : ""}`}>
					<em className="moon-shapes" />
				</div>
				<div className={`hearts ${clicked ? "__clicked anim8" : ""}`}>
					<em className="moon-shapes" />
				</div>
			</div>
		);
	}

	renderModal() {
		const { showImportModal, file, uploadDate, shiftSelected, shifts } = this.state;
		console.log("🚀 ~ file: index.tsx:2661 ~ Orders ~ renderModal ~ shifts:", shifts);
		const { mobile } = this.props;
		let size: any;

		if (file) {
			size = this.humanFileSize(file.size, true);
		}

		return (
			<Modal
				centered={true}
				closable={true}
				onCancel={() => this.setState({ showImportModal: false })}
				visible={showImportModal}
				footer={null}
				bodyStyle={{ padding: 0 }}
				width={mobile ? "100%" : 600}
			>
				<div className="UploadModal">
					<div className="UploadTitle">
						<p>{Strings.settings.uploadMasterData}</p>
					</div>
					<div className="UploadBody">
						<Dropzone
							className="UploadDropzone"
							id="upload_file"
							multiple={false}
							accept="text/csv, .xls, .xlsx"
							onDrop={(files: any) => this.setState({ file: files[0] })}
						>
							{file ? (
								<React.Fragment>
									<p>Name: {file?.name}</p>
									<p>Size: {size}</p>
								</React.Fragment>
							) : (
								<React.Fragment>
									<Icons.UploadFile />
									<p>{Strings.orders.uploadFile}</p>
								</React.Fragment>
							)}
						</Dropzone>
					</div>
					<div className="UploadFooter">
						<form
							method="get"
							// temporary
							action="https://dingoo-dev.s3.eu-central-1.amazonaws.com/dev/files/importacao_master_data.xlsx"
						>
							<button className="DownloadButton" onClick={() => {}}>
								{Strings.orders.download}
							</button>
						</form>
						<button
							className="ImportButton"
							onClick={() => {
								// if (shiftStart > shiftEnd) {
								// 	return notification.warn({
								// 		message: Strings.sidebar.orders,
								// 		description: Strings.errors.shiftDateError,
								// 		placement: "bottomRight",
								// 		duration: 5,
								// 	});
								//} else {
								this.uploadFile();
								//}
							}}
							disabled={!file}
						>
							{Strings.orders.import}
						</button>
					</div>
				</div>
			</Modal>
		);
	}

	renderinvalidUsersModal() {
		const { showInvalidUsersModal, invalidUsers } = this.state;
		const { mobile } = this.props;

		return (
			<Modal
				centered={true}
				closable={true}
				onCancel={() => this.setState({ showInvalidUsersModal: false, invalidUsers: [] })}
				visible={showInvalidUsersModal}
				footer={null}
				bodyStyle={{ padding: 0 }}
				width={mobile ? "100%" : 800}
			>
				<div className="UploadModal">
					<div className="UploadTitle">
						<p>{Strings.orders.importedErrors}</p>
					</div>
					<table className="UploadBody --boxed">
						<thead className="UploadHeaders">
							<tr>
								<th>{"id"}</th>
								<th>{Strings.orders.clientName}</th>
								<th>{Strings.orders.address}</th>
								<th>{Strings.generic.error}</th>
							</tr>
						</thead>
						<tbody className="UploadErrorList">
							{invalidUsers?.map((user: any, index: number) => (
								<tr key={`invalid_order_${index}`} className="InvalidOrderEntry">
									<td>{user.id || "-"}</td>
									<td>{user.ClientName || "-"}</td>
									<td>{user.Address || "-"}</td>
									<td>
										<span>
											{this.getError(user.error)}
											{user.error === "missing_fields" ? <sup>1</sup> : ""}.
										</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="UploadInfo">
						<small>
							1. The required fields are:{" "}
							<span>
								<code>id</code>, <code>Address</code>, <code>latitude</code>, <code>longitude</code>,{" "}
								<code>postCode</code>.
							</span>
						</small>
					</div>
					<div className="UploadFooter">
						<button
							className="DownloadButton"
							onClick={() => this.setState({ showInvalidUsersModal: false, invalidUsers: [] })}
						>
							{Strings.generic.confirm}
						</button>
					</div>
				</div>
			</Modal>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.settings}</title>
					<meta name="description" content="Description of Settings" />
				</Helmet>
				<Row gutter={[25, 25]}>{this.renderOptions()}</Row>
				{this.renderModal()}
				{this.renderinvalidUsersModal()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Settings);
