import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setTitle, updateCrumb, setLoader } from "store/actions";
import { push } from "connected-react-router";
import { Props } from "./types";
import { Col, Input, Select, notification, Row, Tabs } from "antd";
import { ContentWrapper, Icon, Table } from "components";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import { API, Endpoints } from "utils/api";
import { translate } from "utils/utils";
import { DateTime } from "luxon";
import Strings from "utils/strings";
import "./styles.scss";

// @ts-ignore
const { Option } = Select;

export class BusinessDetail extends React.Component<Props, any> {
	phoneValidationInput: any;
	constructor(props: Props) {
		super(props);

		this.state = {
			activeTab: "info",
			isActive: true,
			name: "",
			postalCode: "",
			country: null,
			city: "",
			state: null,
			address: "",
			address2: "",
			language: "pt",
			hasUnsavedFields: false,
			defaultCountries: [],
			countriesOptions: [],
			defaultStates: [],
			statesOptions: [],
			cityOptions: [],
		};
	}

	componentDidMount() {
		const { dispatch, match } = this.props;

		dispatch(setTitle(Strings.sidebar.businesses));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							icon: "partner",
							text: Strings.sidebar.businesses,
							route: "/businesses",
						},
						{
							text:
								match.params.id === "new"
									? Strings.businesses.new
									: translate(this.state.business?.name),
							icon: match.params.id === "new" ? "plus" : "pencil-outline",
						},
					],
					actions: [
						{
							type: "switch",
							value: this.state.isActive,
							onClick: (value: boolean) => this.setState({ isActive: value, hasUnsavedFields: true }),
							text: Strings.generic.active,
							small: {
								label: true,
								switch: true,
							},
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							onClick: this.handleSubmit,
							separator: "left",
							isSave: true,
						},
					],
				};
			}),
		);

		this.getData();
	}

	componentDidUpdate() {
		const { business } = this.state;
		const { dispatch } = this.props;

		dispatch(updateCrumb());
		dispatch(setTitle(`${Strings.sidebar.businesses} - ${business?.name || Strings.businesses.new}`));
	}

	getData = async (requestCountries = true) => {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		if (requestCountries) {
			const countriesResponse = await API.get({
				url: Endpoints.uriCountries(),
			});

			if (countriesResponse.ok) {
				const defaultCountries = countriesResponse.data.results.countries || [];
				const defaultStates: any = {};
				defaultCountries.forEach((country: any) => (defaultStates[country._id] = country?.states || []));

				this.setState({
					defaultCountries,
					countriesOptions: defaultCountries?.map((elem: any, index: number) => (
						<Option key={`country_elem_${index}`} value={elem._id}>
							{elem.name}
						</Option>
					)),
					defaultStates,
				});
			}
		}

		try {
			if (match?.params?.id === "new") {
				dispatch(setLoader(false));
				return;
			}

			const [response] = await Promise.all([
				API.get({
					url: Endpoints.uriBusinesses(match?.params?.id),
				}),
			]);

			if (response?.ok) {
				const { business = {} } = response.data.results;

				this.setState({ business, ...business });
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	};

	validBusiness() {
		const { name, country, state, address } = this.state;

		if (!name?.trim()) {
			notification.warn({
				message: Strings.errors.invalidFields,
				description: Strings.businesses.invalidName,
				placement: "bottomRight",
				duration: 5,
			});

			return false;
		}

		if (!country) {
			notification.warn({
				message: Strings.errors.invalidFields,
				description: Strings.businesses.emptyCountry,
				placement: "bottomRight",
				duration: 5,
			});

			return false;
		}

		if (!state) {
			notification.warn({
				message: Strings.errors.invalidFields,
				description: Strings.businesses.emptyState,
				placement: "bottomRight",
				duration: 5,
			});

			return false;
		}

		if (!address?.trim()) {
			notification.warn({
				message: Strings.errors.invalidFields,
				description: Strings.businesses.emptyAddress,
				placement: "bottomRight",
				duration: 5,
			});

			return false;
		}

		return true;
	}

	handleSubmit = async () => {
		const { name, postalCode, country, city, address, address2, state, logo } = this.state;
		const { dispatch, match } = this.props;

		if (!this.validBusiness()) return;

		dispatch(setLoader(true));

		const request = match.params.id === "new" ? API.post : API.put;

		const body = new FormData();

		body.append("name", name);
		body.append("postalCode", postalCode || "");
		body.append("country", country);
		body.append("city", city || "");
		body.append("address", address);
		body.append("address2", address2 || "");
		body.append("state", state);

		if (logo && Object.keys(logo).length > 0) {
			if (logo.file) {
				body.append("logo", logo.file);
			} else {
				body.append("logo", logo);
			}
		}

		try {
			const response = await request({
				url: Endpoints.uriBusinesses(match?.params?.id === "new" ? "" : match?.params?.id),
				data: body,
			});

			if (response?.ok) {
				const { business = {} } = response.data.results;

				this.setState({ business, ...business, hasUnsavedFields: false });

				notification.success({
					message: Strings.businesses.header,
					description: Strings.businesses.edited,
					placement: "bottomRight",
					duration: 5,
				});

				if (match?.params?.id === "new") {
					const { users } = business;
					this.setState({ users });
					dispatch(push("/businesses"));
					dispatch(push(`/businesses/${business._id}`));
				}
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response.data.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	};

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: any, isLogo = false) {
		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						if (isLogo) {
							this.setState({
								logo: { file: result, preview: res },
								hasUnsavedFields: true,
							});
						}
					});
				},
			});
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5,
			});
		}
	}

	async addContract() {
		const { tempContract, business } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let response: any;
		try {
			const request = tempContract?._id ? API.put : API.post;
			response = await request({
				url: Endpoints.uriBusinesses(`${business._id}/contracts/${tempContract?._id || ""}`),
				data: {
					...tempContract,
				},
			});

			if (response.ok) {
				await this.getData(false);

				notification.success({
					message: Strings.businesses.contracts,
					description: tempContract?._id
						? Strings.businesses.contractEdited
						: Strings.businesses.contractAdded,
					placement: "bottomRight",
					duration: 5,
				});

				this.setState({ tempContract: {}, openContractDrawer: false });
			} else {
				notification.error({
					message: Strings.businesses.contracts,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.businesses.contracts,
				description: response?.data?.message || (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async deleteContract(id: string) {
		const { business } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.delete({
				url: Endpoints.uriBusinesses(`${business._id}/contracts/${id}`),
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.businesses.contracts,
					description: Strings.businesses.contractRemoved,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.businesses.contracts,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.businesses.contracts,
				description: response?.data?.message || (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	renderGeneralInfo() {
		const { name, address, address2, postalCode, country, city, state, logo, countriesOptions, defaultStates } =
			this.state;

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24} md={6}>
					<label htmlFor="image_upload" className="InputLabel">
						{Strings.fields.logo}
					</label>
					<div className="BusinessMainImage">
						<Dropzone
							accept="image/jpg, image/jpeg, image/png"
							className="GenericImageUpload"
							id="image_upload"
							onDrop={(files: any) => this.onDrop(files, true)}
						>
							{logo ? (
								<div
									className="BusinessImage"
									style={{
										backgroundImage: (logo && `url('${logo?.preview || logo}')`) || "none",
										backgroundSize: "contain",
									}}
								/>
							) : (
								<div className={`GenericImageUploadOverlay${!logo ? " --visible" : ""}`}>
									<Icon name="frame" />
									<span>{Strings.generic.changeImage}</span>
								</div>
							)}
							{logo && (
								<button
									onClick={(e: React.MouseEvent<HTMLElement>) => {
										e.stopPropagation();
										this.setState({ logo: null, hasUnsavedFields: true });
									}}
									className="GenericImageDelete"
								>
									<Icon name="close" />
								</button>
							)}
						</Dropzone>
					</div>
				</Col>
				<Col xs={24} md={18}>
					<Row gutter={[20, 10]}>
						<Col xs={24} md={24}>
							<label htmlFor="business_name" className="InputLabel --label-required">
								{Strings.fields.name}
							</label>
							<Input
								id="business_name"
								value={name || ""}
								placeholder={Strings.fields.name}
								onChange={(event: any) => {
									const value = event.target.value;
									this.setState({ name: value, hasUnsavedFields: true });
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[20, 10]}>
						<Col xs={24} md={12}>
							<label htmlFor="business_address_1" className="InputLabel --label-required">
								{Strings.businesses.addressLine1}
							</label>
							<Input
								id="business_address_1"
								value={address || ""}
								placeholder={Strings.businesses.addressLine1}
								onChange={(event: any) => {
									const value = event.target.value;
									this.setState({ address: value, hasUnsavedFields: true });
								}}
							/>
						</Col>
						<Col xs={24} md={6}>
							<label htmlFor="business_address_2" className="InputLabel">
								{Strings.businesses.addressLine2}
							</label>
							<Input
								id="business_address_2"
								value={address2 || ""}
								placeholder={Strings.businesses.addressLine2}
								onChange={(event: any) => {
									const value = event.target.value;
									this.setState({ address2: value, hasUnsavedFields: true });
								}}
							/>
						</Col>
						<Col xs={24} md={6}>
							<label htmlFor="postal_code" className="InputLabel">
								{Strings.fields.zipCode}
							</label>
							<Input
								id="postal_code"
								placeholder={Strings.fields.zipCode}
								value={postalCode || ""}
								onChange={(event: any) => {
									const value = event.target.value;
									this.setState({ postalCode: value, hasUnsavedFields: true });
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[20, 10]}>
						<Col xs={24} md={8}>
							<label htmlFor="country" className="InputLabel --label-required">
								{Strings.fields.country}
							</label>
							<Select
								key={`country_${country}`}
								className="tagsSelector"
								style={{ width: "100%" }}
								placeholder={Strings.fields.country}
								showSearch
								disabled={false}
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								value={country || null}
								onChange={(elem: any) => {
									this.setState({
										country: elem,
										state: null,
										hasUnsavedFields: true,
									});
								}}
							>
								{countriesOptions}
							</Select>
						</Col>
						<Col xs={24} md={8}>
							<label htmlFor="state" className="InputLabel --label-required">
								{Strings.fields.state}
							</label>
							<Select
								id="state"
								key={`state_${state}`}
								className="tagsSelector"
								style={{ width: "100%" }}
								placeholder={Strings.fields.state}
								showSearch
								filterOption={(input: any, option: any) =>
									option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								value={state || null}
								disabled={!country}
								options={
									country
										? defaultStates[country].map((state: any) => ({
												value: state?._id,
												label: state?.name,
										  }))
										: null
								}
								onChange={(elem: any) => {
									this.setState({
										state: elem,
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} md={8}>
							<label htmlFor="city" className="InputLabel">
								{Strings.fields.city}
							</label>
							<Input
								id="city"
								placeholder={Strings.fields.city}
								value={city || ""}
								onChange={(event: any) => {
									const value = event.target.value;
									this.setState({
										city: value,
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

	renderContracts() {
		const { contracts } = this.state;
		const { dispatch, match } = this.props;

		return (
			<Table
				title={{
					icon: "file",
					title: Strings.businesses.contracts,
				}}
				data={contracts}
				columns={[
					{
						Header: Strings.businesses.typeContract,
						id: "type",
						accessor: (row: any) => (row.type && row.type.name) || "-",
						type: "text",
					},
					{
						Header: Strings.businesses.descriptionContract,
						id: "description",
						accessor: (row: any) => row.description || "-",
						type: "text",
					},
					{
						Header: Strings.fields.startDate,
						id: "startDate",
						accessor: (row: any) =>
							(row.startDate && DateTime.fromISO(row.startDate).toFormat("dd/LL/yyyy")) || "-",
						type: "date",
						Filter: () => {},
						maxWidth: 200,
					},
					{
						Header: Strings.fields.endDate,
						id: "endDate",
						accessor: (row: any) =>
							(row.endDate && DateTime.fromISO(row.endDate).toFormat("dd/LL/yyyy")) || "-",
						type: "date",
						Filter: () => {},
						maxWidth: 200,
					},
				]}
				filterable
				sortable
				add={{
					onClick: () => dispatch(push(`/businesses/${match.params.id}/contracts/new`)),
				}}
				actions={{
					edit: (original: any) => ({
						onClick: () => dispatch(push(`/businesses/${match.params.id}/contracts/${original._id}`)),
					}),
					remove: (original: any) => ({
						onClick: () => this.deleteContract(original._id),
					}),
				}}
			/>
		);
	}

	render() {
		const { activeTab } = this.state;
		const { match } = this.props;

		return (
			<div className="ScreenClientDetail">
				<Helmet>
					<title>{Strings.sidebar.businesses}</title>
					<meta name="description" content="Define your business information and settings" />
				</Helmet>
				<ContentWrapper>
					<Tabs defaultActiveKey="info" onChange={(key: string) => this.setState({ activeTab: key })}>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="partner" />
									<p style={{ margin: 0 }}>{Strings.businesses.info}</p>
								</div>
							}
							key="info"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="file" />
									<p style={{ margin: 0 }}>{Strings.businesses.contracts}</p>
								</div>
							}
							disabled={match?.params?.id === "new"}
							key="contracts"
						/>
					</Tabs>
					{activeTab === "info" && this.renderGeneralInfo()}
					{activeTab === "contracts" && this.renderContracts()}
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	selectedBusiness: state.selectedBusiness,
	user: state.user,
});

export default connect(mapStateToProps)(BusinessDetail);
