import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { delayedDispatch, setBreadcrumb, setLoader, setOrderFilters, setTitle, updateCrumb } from "store/actions";
import {
	Checkbox,
	Col,
	Drawer,
	Input,
	Modal,
	notification,
	Row,
	Select,
	Tabs,
	Tooltip,
	TimePicker,
	DatePicker,
	Progress,
	Divider,
} from "antd";
import { ContentWrapper, Icon, Icons, Table } from "components";
import Dropzone from "react-dropzone";
import { capitalize } from "utils/utils";
import { Duration } from "luxon";
import moment from "moment";
import 'moment-timezone';
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";

import xlsx from "json-as-xlsx";

// @ts-ignore
import Variables from "styles/variables.scss";
import "./styles.scss";
import { AnyAaaaRecord } from "dns";
import Shifts from "screens/Shifts";
import { SolutionOutlined } from "@ant-design/icons";

class Orders extends React.Component<any, any> {
	preventRefresh: any;

	constructor(props: any) {
		super(props);

		// let packingEntries: any = { type: "move_from_packing", orders: [] };
		// if (props.orderFilters?.tab === "packing") {
		// 	if (props.orderFilters?.contract?.type === "parcel") {
		// 		packingEntries = { type: "create_box", orders: [] };
		// 	}
		// }

		// let pickingEntries: any = { type: "picking", orders: [] };
		// if (props.orderFilters?.tab === "picking") {
		// 	if (props.orderFilters?.contract?.type === "parcel") {
		// 		packingEntries = { type: "create_box", orders: [] };
		// 	}
		// }

		this.state = {
			orders: [],
			activeTab: props.orderFilters?.tab || "registered",
			showImportModal: false,
			defaultBusinesses: [],
			defaultContracts: [],
			selectedDate:
				(props.orderFilters?.date &&
					moment.utc(props.orderFilters?.date).isValid() &&
					moment.utc(props.orderFilters?.date)) ||
				null,
			selectedBusiness: props.orderFilters?.business ?? null,
			selectedContract: props.orderFilters?.contract?.contract ?? null,

			total: 0,
			page: 0,
			pageSize: 10,
			globalSearch: "",
			filters: {},

			preventCall: false,
			showInvalidOrdersModal: false,
			invalidOrders: [],
			addressesEntries: { type: "valid", orders: [] },
			pickingEntries: { orders: [] },
			packingEntries: { orders: [] },
			aggregationEntries: { orders: [] },
			packingType: "box",
			rowToChange: null,
			newBoxIdAggregation: "",
			ordersToLoad: [],
			routes: [],
			grids: [],
			selectedRouteGrid: "",
			selectedRouteGridId: "",
			selectedRoute: {},
			routesToVan: [],
			routesToDispatch: [],
			vehicles: [],
			selectedRouteVehicle: "",
			selectedRouteVehicleId: "",
			routesToTransit: [],
			drivers: [],
			selectedRouteDriver: "",
			selectedRouteDriverId: "",
			shiftStart: "",
			shiftEnd: "",
			uploadDate: "",
			expandedOrderInRoute: "",
			shifts: [],
			loadedOrders: true,
			selectedShift: "",
			isOrderInDeliverySteps: false,
			orderDeliveredTime: "",
			isReturnToPacking: false,
			multiplyWeight: 0,
			multiplyValue: 0,
			multiplyBoxId: "",
		};
	}

	getStatusName = (status: string) => {
		console.log("🚀 ~ 114 Orders ~ status:", status);
		if (status === "on_transit") {
			return Strings.orders.on_transit;
		} else if (status === "dispatching") {
			return "Dispatching";
		} else if (status === "delivered") {
			return Strings.orders.orderDelivered;
		} else if (status === "not_delivered") {
			return Strings.orders.orderNotDelivered;
		} else if (status === "cancelled") {
			return Strings.orders.orderCancelled;
		} else if (status === "dispute") {
			return Strings.orders.complaints;
		} else if (status === "packing") {
			return Strings.orders.packing;
		} else if (status === "billing") {
			return Strings.orders.billing;
		} else {
			return "-";
		}
	};

	async componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		console.log("props.orderFilters?.date", this.props.orderFilters?.date);
		console.log("this.state", this.state);

		await Promise.all([this.getInitialData(), this.getData()]);

		this.breadCrumb();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
	}

	async getOrders(pause = false) {
		const {
			activeTab,
			columnSearch,
			page,
			pageSize,
			globalSearch,
			selectedDate,
			selectedBusiness,
			selectedContract,
		} = this.state;
		const { dispatch } = this.props;

		if (
			activeTab !== "registered" &&
			activeTab !== "address_confirmation" &&
			(!selectedBusiness || !selectedContract || !selectedDate)
		) {
			return;
		}

		setTimeout(
			async () => {
				if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;

				dispatch(setLoader(true));

				const body = {
					filters: columnSearch
						? Object.keys(columnSearch).map((elem) =>
								columnSearch[elem] !== ""
									? {
											field: elem,
											query: columnSearch[elem],
									  }
									: {},
						  )
						: [],
					page,
					pageSize,
					search: globalSearch,
					status: activeTab,
				} as any;

				if (selectedBusiness) {
					body.business = selectedBusiness;
				}

				if (selectedContract) {
					body.contract = selectedContract;
				}

				if (selectedDate) {
					body.date = moment.utc(selectedDate).format("YYYY-MM-DD");
				}

				let response: any;
				try {
					response = await API.post({
						url: Endpoints.uriOrders("search"),
						data: body,
					});

					console.log("🚀 ~ file: index.tsx:184 ~ Orders ~ response.data.results:", response.data.results);

					if (response.ok) {
						const { orders, total, shifts } = response.data.results || {};
						console.log(">>>>>>>>>>>>>Orders ~ shifts:", shifts[0]?.shifts);

						this.setState({ orders, total, shifts: shifts[0]?.shifts });
					} else {
						notification.error({
							message: Strings.sidebar.orders,
							description: response?.data?.message || Strings.serverErrors.wentWrong,
							placement: "bottomRight",
							duration: 5,
						});
					}
				} catch (err) {
					notification.error({
						message: Strings.serverErrors.title,
						description: Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5,
					});
				}

				dispatch(setLoader(false));
			},
			pause ? 1000 : 0,
		);
	}

	async getOrdersToAggregate() {
		const { activeTab, selectedBusiness, selectedContract, selectedDate } = this.state;
		const { dispatch } = this.props;

		if (
			activeTab !== "registered" &&
			activeTab !== "address_confirmation" &&
			(!selectedBusiness || !selectedContract || !selectedDate)
		) {
			return;
		}

		setTimeout(async () => {
			dispatch(setLoader(true));

			const body = {} as any;

			if (selectedBusiness) {
				body.business = selectedBusiness;
			}

			if (selectedContract) {
				body.contract = selectedContract;
			}

			let response: any;

			try {
				response = await API.post({
					url: Endpoints.uriOrdersToAggregate(),
					data: body,
				});

				if (response.ok) {
					const { orders, ordersToAggregate } = response.data.results || {};
					let auxId = 1;
					for (const obj of ordersToAggregate) {
						obj.boxName = Strings.orders.box + " " + auxId;
						if (obj.temporary && !obj.registeredId) obj.boxId = "";
						for (const bag of obj.bags) {
							bag.boxOrderId = obj._id;
						}
						auxId++;
					}
					this.setState({ orders, ordersToAggregate });
				} else {
					notification.error({
						message: Strings.sidebar.orders,
						description: response?.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5,
					});
				}
			} catch (err) {
				notification.error({
					message: Strings.serverErrors.title,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}

			dispatch(setLoader(false));
		});
	}

	async getRoutesData(status = "loading") {
		const { activeTab, selectedBusiness, selectedContract, selectedDate, pageSize, page } = this.state;
		const { dispatch } = this.props;

		if (
			activeTab !== "registered" &&
			activeTab !== "address_confirmation" &&
			(!selectedBusiness || !selectedContract || !selectedDate)
		) {
			return;
		}

		setTimeout(async () => {
			dispatch(setLoader(true));

			const headers = {} as any;

			if (selectedBusiness) {
				headers.business = selectedBusiness;
			}

			if (selectedContract) {
				headers.contract = selectedContract;
			}

			let response: any;

			try {
				response = await API.get({
					url: `${Endpoints.uriRoutes()}status/${status}?page=${page}&limit=${pageSize}`,
					headers,
					params: { date: moment.utc(selectedDate).format("YYYY-MM-DD") },
				});

				if (response.ok) {
					const { routes, grids, vehicles, drivers, pagination } = response.data.results;
					this.setState({
						routes,
						grids,
						vehicles,
						drivers,
						total: pagination.totalRoutes,
						page: pagination.currentPage,
					});
				} else {
					notification.error({
						message: Strings.sidebar.orders,
						description: response?.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5,
					});
				}
			} catch (err) {
				notification.error({
					message: Strings.serverErrors.title,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}

			dispatch(setLoader(false));
		});
	}

	async aggregate(boxId: any, registeredId: boolean, box: any) {
		const { dispatch } = this.props;
		const { selectedBusiness, selectedContract, ordersToAggregate } = this.state;

		let ordersIds = [] as any;

		if (box?.bags.length > 0) {
			for (let index = 0; index < box?.bags.length; index++) {
				const bag = box?.bags[index];
				if (!!registeredId) {
					ordersIds.push(bag.orderId);
				} else {
					if (!!bag.treated) ordersIds.push(bag.orderId);
				}
			}
		}

		setTimeout(async () => {
			dispatch(setLoader(true));

			const body = {} as any;

			if (selectedBusiness) {
				body.business = selectedBusiness;
			}

			if (selectedContract) {
				body.contract = selectedContract;
			}

			body.boxId = boxId;
			body.orders = ordersIds;
			body.registeredId = registeredId;

			let response: any;
			try {
				response = await API.patch({
					url: Endpoints.uriAggregation(),
					data: body,
				});

				if (response.ok) {
					console.log("response aggregation", response);
					this.getData();
				} else {
					notification.error({
						message: Strings.sidebar.orders,
						description: response?.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5,
					});
				}
			} catch (err) {
				notification.error({
					message: Strings.serverErrors.title,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}

			dispatch(setLoader(false));
		});
	}

	async updateBag(orderId: any, flowId: any, treated: boolean) {
		this.props.dispatch(setLoader(true));
		try {
			const response = await API.put({
				url: Endpoints.uriOrders(orderId),
				data: {
					treated: treated,
					flowId: flowId,
				},
			});
			console.log("🚀 ~ file: api.tsx:78 ~ callUpdateBag ~ response:", response);

			if (response.ok) {
				this.getData();
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			this.props.dispatch(setLoader(false));
		}
	}

	async updateOrderStatus() {
		this.props.dispatch(setLoader(true));
		const { selectedOrderId, tempOrder } = this.state;
		console.log("🚀 ~ Orders ~ updateOrderStatus ~ tempOrder:", tempOrder);

		const data: any = {
			arrivalTime: tempOrder.arrivalTime,
			departureTime: tempOrder.departureTime,
			orderFlow: tempOrder.orderFlow,
			activeStatus: this.state.activeTab,
		};

		try {
			const response = await API.put({
				url: Endpoints.uriOrders(selectedOrderId),
				data,
			});

			if (response.ok) {
				this.setState({ isOrderInDeliverySteps: false, showDrawer: false }, () => this.getData());
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			this.props.dispatch(setLoader(false));
		}
	}

	async returnToPacking() {
		this.props.dispatch(setLoader(true));
		const { selectedOrderId, tempOrder } = this.state;

		const data: any = {
			slot: tempOrder.orderSlot,
			orderFlow: { ...tempOrder.orderFlow, status: "packing" },
			deliveryDate: tempOrder.deliveryDate,
			activeStatus: this.state.activeTab,
		};

		try {
			const response = await API.put({
				url: Endpoints.uriOrders(selectedOrderId),
				data,
			});

			if (response.ok) {
				this.setState({ isOrderInDeliverySteps: false, showDrawer: false }, () => this.getData());
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			this.props.dispatch(setLoader(false));
		}
	}

	async selectRouteDetails(routeId: any, idToChange: any, type = "grid") {
		let data = {} as any;
		const headers = {} as any;
		const { selectedBusiness, selectedContract } = this.state;

		if (selectedBusiness) {
			headers.business = selectedBusiness;
		}

		if (selectedBusiness) {
			headers.contract = selectedContract;
		}

		if (type === "grid") {
			data.gradeId = idToChange;
		} else if (type === "vehicle") {
			data.vehicle = idToChange;
		} else if (type === "driver") {
			data.driver = idToChange;
		}

		this.props.dispatch(setLoader(true));
		try {
			const response = await API.put({
				url: Endpoints.uriRoutes(routeId),
				data,
				headers,
			});

			if (response.ok) {
				this.setState({ showDrawer: false }, () => this.getData());
			} else {
				this.setState(
					{
						showDrawer: false,
					},
					() =>
						notification.error({
							message: Strings.orders.routes,
							description: response?.data?.message || Strings.serverErrors.wentWrong,
							placement: "bottomRight",
							duration: 5,
						}),
				);
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			this.props.dispatch(setLoader(false));
		}
	}

	async archiveOrder(orderId: any) {
		const headers = {} as any;
		const { selectedBusiness, selectedContract } = this.state;

		if (selectedBusiness) {
			headers.business = selectedBusiness;
		}

		if (selectedContract) {
			headers.contract = selectedContract;
		}

		this.props.dispatch(setLoader(false));

		let data = { archive: true };

		try {
			let response = await API.put({
				url: Endpoints.uriOrders(orderId),
				data,
				displayLoader: false,
			});

			if (response.ok) {
				await this.getData(true);

				notification.success({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.orders.edited,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		this.props.dispatch(setLoader(false));
	}

	async moveToNextStep(status = "loading") {
		const { routesToTransit, routesToDispatch, routesToVan, selectedBusiness, selectedContract } = this.state;

		const headers = {} as any;
		let routeIds = [] as any;

		if (selectedBusiness) {
			headers.business = selectedBusiness;
		}

		if (selectedContract) {
			headers.contract = selectedContract;
		}

		if (status === "loading") {
			routeIds = routesToVan.map((obj: any) => obj._id);
		} else if (status === "ready_to_van") {
			routeIds = routesToDispatch.map((obj: any) => obj._id);
		} else {
			routeIds = routesToTransit.map((obj: any) => obj._id);
		}

		let response = {} as any;

		this.props.dispatch(setLoader(true));
		try {
			response = await API.patch({
				url: Endpoints.uriRoutes("status"),
				data: {
					status,
					routeIds,
				},
				headers,
				axiosConfig: { timeout: 60000 },
			});

			if (response.ok) {
				this.setState({ showDrawer: false, routesToVan: [], routesToTransit: [], routesToDispatch: [] }, () =>
					this.getData(),
				);
			} else {
				notification.error({
					message: Strings.orders.routes,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		} finally {
			this.props.dispatch(setLoader(false));
		}
	}

	async patchOnAggregation(order = {}, aggregationEnded: boolean) {
		const { ordersToLoad, selectedBusiness, selectedContract } = this.state;

		const headers = {} as any;

		if (selectedBusiness) {
			headers.business = selectedBusiness;
		}

		if (selectedContract) {
			headers.contract = selectedContract;
		}

		let auxOrders = [];
		auxOrders.push(order);
		console.log("🚀 ~ file: index.tsx:510 ~ Orders ~ patchOnAggregation ~ auxOrders:", auxOrders);
		let auxOrderIds = auxOrders.map((obj: any) => obj.orderFlowId);
		let ordersToLoadIds = ordersToLoad.map((obj: any) => obj.orderFlowId);

		this.props.dispatch(setLoader(true));
		try {
			const response = await API.patch({
				url: Endpoints.uriOrders("status"),
				data: {
					status: "aggregation",
					orders: aggregationEnded ? ordersToLoadIds : auxOrderIds,
					aggregationEnded,
				},
				headers,
			});

			if (response.ok) {
				this.setState({ showDrawer: false }, () => this.getData());
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			this.props.dispatch(setLoader(false));
		}
	}

	getData(pause = false) {
		const { activeTab } = this.state;

		if (
			activeTab === "loading" ||
			activeTab === "ready_to_van" ||
			activeTab === "on_transit" ||
			activeTab === "dispatching"
		) {
			this.getRoutesData(activeTab);
		} else if (activeTab === "aggregation") {
			this.getOrdersToAggregate();
		} else {
			this.getOrders(pause);
		}
	}

	async getInitialData() {
		const { selectedBusiness } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.get({
				url: Endpoints.uriBusinesses("orders"),
			});

			if (response.ok) {
				const { businesses: defaultBusinesses } = response.data.results || {};

				let defaultContracts = [] as any;
				if (selectedBusiness) {
					const tempBusiness = defaultBusinesses.find((entry: any) => entry._id === selectedBusiness);

					if (tempBusiness) {
						defaultContracts = JSON.parse(JSON.stringify(tempBusiness.contracts || []));
					}
				}

				this.setState({ defaultBusinesses, defaultContracts });
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async patchAddressesOrders() {
		const { addressesEntries } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.patch({
				url: Endpoints.uriOrders("status"),
				data: {
					orders: addressesEntries?.orders,
					status: "address_confirmation",
				},
			});

			if (response.ok) {
				this.setState({ addressesEntries: { type: "valid", orders: [] } });
				await this.getData();

				notification.success({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.orders.editedPlural,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async patchAddresses() {
		const { addressesEntries } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.patch({
				url: Endpoints.uriOrders("addresses"),
				data: {
					orders: addressesEntries?.orders,
				},
			});

			if (response.ok) {
				this.setState({ addressesEntries: { type: "valid", orders: [] } });
				await this.getData();

				notification.success({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.orders.editedPlural,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	checkForApiCalls() {
		const { activeTab, selectedDate, selectedBusiness, selectedContract } = this.state;

		if (
			(activeTab === "registered" ||
				activeTab === "address_confirmation" ||
				(selectedDate && selectedBusiness && selectedContract)) &&
			activeTab !== "aggregation"
		) {
			this.getData();
		}
	}

	breadCrumb() {
		console.log("this.state.selectedDate", this.props.orderFilters?.date);
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [],
					actions: [
						{
							type: "select",
							text: Strings.businesses.business,
							placeholder: Strings.orders.selectBusiness,
							value: this.state.selectedBusiness,
							options: this.state.defaultBusinesses.map((business: any) => ({
								value: business._id,
								text: business.name,
							})),
							onChange: (business: any) => {
								if (business !== this.state.selectedBusiness) {
									this.props.dispatch(
										setOrderFilters({ ...this.props.orderFilters, business, contract: null }),
									);

									const contracts =
										(business &&
											this.state.defaultBusinesses.find((entry: any) => entry._id === business)
												?.contracts) ||
										[];

									this.setState(
										{
											selectedBusiness: business,
											defaultContracts: business
												? JSON.parse(JSON.stringify(contracts || []))
												: [],
											selectedContract: null,
											orders: [],
										},
										() => this.checkForApiCalls(),
									);
								}
							},
							minWidth: 170,
							maxWidth: 170,
							allowClear: true,
						},
						{
							type: "select",
							text: Strings.dashboard.contract,
							placeholder: Strings.orders.selectContract,
							value: this.state.selectedContract,
							disabled: !this.state.selectedBusiness,
							options: this.state.defaultContracts.map((contract: any) => ({
								value: contract._id,
								text: `${contract.type?.name + " "} (${moment
									.utc(contract.startDate)
									.format("DD/MM/YYYY")} - ${moment.utc(contract.endDate).format("DD-MM-YYYY")})`,
							})),
							onChange: (contract: any) => {
								if (contract !== this.state.selectedContract) {
									const tempContract = this.state.defaultContracts.find(
										(cnt: any) => cnt._id === contract,
									);
									const saveContract = { type: tempContract?.type?.type || "parcel", contract };

									this.props.dispatch(
										setOrderFilters({ ...this.props.orderFilters, contract: saveContract }),
									);

									this.setState(
										{
											selectedContract: contract,
											orders: [],
											addressesEntries: { type: "valid", orders: [] },
											packingEntries: {
												type:
													tempContract?.type?.type === "parcel"
														? "create_box"
														: "move_from_packing",
												orders: [],
											},
										},
										() => this.checkForApiCalls(),
									);
								}
							},
							minWidth: 170,
							maxWidth: 170,
							margin: "left",
							allowClear: true,
						},
						{
							type: "datePicker",
							isSingleDate: true,
							separator: "left",
							text: Strings.fields.date,
							dates: this.state.selectedDate,
							onChange: (date: any) => {
								this.props.dispatch(
									setOrderFilters({ ...this.props.orderFilters, date: moment.utc(date).utc() }),
								);

								if (
									!date &&
									this.state.activeTab !== "registered" &&
									this.state.activeTab !== "address_confirmation"
								) {
									this.setState({ selectedDate: date, orders: [] });
								} else {
									this.setState({ selectedDate: date }, () => this.checkForApiCalls());
								}
							},
							className: "fixedPicker",
							allowClear: true,
							inputReadOnly: true,
						},
					],
				};
			}),
		);
	}

	get pagination() {
		console.log("🚀 ~ file: index.tsx:1029 ~ Orders ~ getpagination ~ this.state.total:", this.state.total);
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				this.setState({ page, pageSize: size }, () => this.getData());
			},
		};
	}

	get filtration() {
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: false,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				this.setState({ globalSearch, page: 0 }, () => this.getData(true));
			},
			onColumnSearch: (columnSearch: string) => {
				this.setState({ columnSearch, page: 0 }, () => this.getData(true));
			},
		};
	}

	isValid = (obj: any) => {
		const { orderErrors } = obj;
		const { date, postCode, address, coordinates } = orderErrors || {};

		return !date && !postCode && !address && !coordinates;
	};

	async uploadFile() {
		const { file, selectedBusiness, selectedContract, uploadDate, selectedShift, activeTab } = this.state;
		const { dispatch } = this.props;

		const data = new FormData();
		data.append("files", file);
		data.append("business", selectedBusiness);
		data.append("contract", selectedContract);
		data.append("batchShift", selectedShift);
		data.append("batchDate", moment(uploadDate).format("YYYY-MM-DD"));

		dispatch(setLoader(true));

		let response: any;
		try {
			response =
				activeTab !== "packing"
					? await API.post({
							url: Endpoints.uriOrders("import"),
							data,
					  })
					: await API.put({
							url: Endpoints.uriOrders("import-boxes"),
							data,
					  });

			if (response.ok) {
				const { invalidOrders = [] } = response.data.results || {};
				const showInvalidOrdersModal = invalidOrders.length > 0 ? true : false;

				this.setState(
					{ showImportModal: false, file: null, showInvalidOrdersModal, invalidOrders },
					async () => {
						if (!showInvalidOrdersModal) {
							await this.getData();
						}
					},
				);

				notification.success({
					message: Strings.sidebar.orders,
					description: showInvalidOrdersModal ? Strings.orders.importedWithErrors : Strings.orders.imported,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.sidebar.orders,
				description: response?.data?.message || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async savePackaging() {
		const { tempOrder, packingType } = this.state;
		const { dispatch } = this.props;
		const { boxes = [], bagId, bagWeight } = tempOrder.orderFlow || {};

		if (packingType === "bag" && (!bagId?.trim() || bagWeight == null)) {
			return notification.warn({
				message: Strings.sidebar.orders,
				description: Strings.orders.fillBagFields,
				placement: "bottomRight",
				duration: 5,
			});
		} else if (packingType === "box") {
			if (boxes.length === 0) {
				return notification.warn({
					message: Strings.sidebar.orders,
					description: Strings.orders.fillOneBox,
					placement: "bottomRight",
					duration: 5,
				});
			}

			if (boxes.some((box: any) => !box.id || !box.weight)) {
				console.log("boxes", boxes);
				return notification.warn({
					message: Strings.sidebar.orders,
					description: Strings.orders.boxMissingFields,
					placement: "bottomRight",
					duration: 5,
				});
			}
		}

		const body: any = {
			orderFlow: tempOrder.orderFlow,
		};

		if (packingType === "bag") {
			body.orderFlow.bagId = bagId;
			body.orderFlow.bagWeight = bagWeight;
			body.orderFlow.boxes = [];
			body.activeStatus = this.state.activeTab;
		} else if (packingType === "box") {
			body.orderFlow.bagId = "";
			body.orderFlow.bagWeight = null;
			body.orderFlow.boxes = boxes;
			body.activeStatus = this.state.activeTab;
		}

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.put({
				url: Endpoints.uriOrders(tempOrder._id),
				data: body,
			});

			if (response.ok) {
				await this.getData(true);

				this.setState({ tempOrder: null, showDrawer: false, packingType: "bag" });

				notification.success({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.orders.edited,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async moveToPacking() {
		const { pickingEntries } = this.state;
		const { dispatch } = this.props;

		if (!pickingEntries.orders?.length) return;

		dispatch(setLoader(true));

		try {
			let response = await API.patch({
				url: Endpoints.uriOrders("status"),
				data: {
					status: "picking",
					orders: pickingEntries?.orders,
				},
			});
			if (response.ok) {
				await this.getData();

				this.setState({ pickingEntries: { orders: [] } });

				notification.success({
					message: Strings.sidebar.orders,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {}

		dispatch(setLoader(false));
	}

	async packBags(entries: any, aggregationEnded = false) {
		const { selectedContract, ordersToAggregate } = this.state;
		const { dispatch } = this.props;

		if (!entries.orders?.length) return;

		dispatch(setLoader(true));

		let auxPackingEntries = entries?.orders?.map((order: any) => order._id);

		console.log("auxPackingEntries", auxPackingEntries);

		try {
			let response = await API.patch({
				url: Endpoints.uriOrders("status"),
				data: {
					status: "packing",
					orders: auxPackingEntries,
					aggregationEnded,
				},
				headers: {
					contract: selectedContract,
				},
				axiosConfig: { timeout: 60000 },
			});
			if (response.ok) {
				await this.getData();

				if (ordersToAggregate?.length > 0) {
					this.setState({ packingEntries: { orders: [] } });
				} else {
					this.setState({ aggregationEntries: { orders: [] } });
				}
				notification.success({
					message: Strings.sidebar.orders,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {}

		dispatch(setLoader(false));
	}

	humanFileSize(bytes: number, si = false, dp = 1) {
		const thresh = si ? 1000 : 1024;

		if (Math.abs(bytes) < thresh) {
			return bytes + " B";
		}

		const units = si
			? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
			: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
		let u = -1;
		const r = 10 ** dp;

		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

		return bytes.toFixed(dp) + " " + units[u];
	}

	getError(error: string) {
		switch (error) {
			case "order_id":
				return Strings.orders.missingOrderId;
			case "order_not_found":
				return "A encomenda não existe";
			case "invalid_postcode":
				return Strings.orders.invalidPostCode;
			case "missing_fields":
				return Strings.orders.missingMandatoryFields;
			case "invalid_date_format":
				return Strings.orders.invalidDateFormat;
			case "invalid_slot":
				return Strings.orders.invalidSlot;
			default:
				return error;
		}
	}

	getTableColumns() {
		const {
			activeTab,
			addressesEntries,
			orders,
			defaultContracts,
			selectedContract,
			pickingEntries,
			packingEntries,
			ordersToAggregate,
			aggregationEntries,
		} = this.state;

		let defaultColumns: any = [
			{
				Header: Strings.orders.orderId,
				id: "orderId",
				accessor: (row: any) => row.orderId || "-",
				multiline: true,
				minWidth: 150,
			},
			{
				Header: Strings.orders.deliveryDate,
				id: "deliveryDate",
				accessor: (row: any) =>
					(row.orderFlow?.deliveryDate && moment.utc(row.orderFlow.deliveryDate).format("DD-MM-YYYY")) || "-",
			},
		];

		let counter = 1;

		switch (activeTab) {
			case "registered":
				defaultColumns.push(
					{
						Header: Strings.orders.deliverySlot,
						id: "slot",
						accessor: (row: any) => {
							if (row.slot?.from && row.slot?.to) {
								return `${Duration.fromMillis(row.slot.from).toFormat("hh:mm")} - ${Duration.fromMillis(
									row.slot.to,
								).toFormat("hh:mm")}`;
							}

							return "-";
						},
						maxWidth: 115,
					},
					{
						Header: "",
						id: "orderErrors",
						Cell: (cell: any) => {
							const orderErrors = cell.row?.original?.orderErrors || {};
							const title = [];

							if (orderErrors?.date) {
								title.push(<li key="date_error">{Strings.orders.validationDateError}</li>);
							}

							if (orderErrors?.address) {
								title.push(<li key="address_error">{Strings.orders.validationAddressError}</li>);
							}

							if (orderErrors?.postCode) {
								title.push(<li key="postcode_error">{Strings.orders.validationPostCodeError}</li>);
							}

							if (orderErrors?.coordinates) {
								title.push(
									<li key="coordinates_error">{Strings.orders.validationCoordinatesError}</li>,
								);
							}

							if (title.length > 0) {
								return (
									<Tooltip
										color={Variables.primaryColor}
										title={
											<ul className="TableOrderUnorderedList">
												{title.map((entry: any) => entry)}
											</ul>
										}
									>
										<div className="TableOrderStatus" />
									</Tooltip>
								);
							}

							return <div className="TableOrderStatus --order-confirmed" />;
						},
						maxWidth: 36,
					},
					{
						Header: Strings.orders.clientName,
						id: "user",
						accessor: (row: any) => row.user || "-",
					},
					{
						Header: Strings.orders.orderAddress,
						id: "orderAddress",
						accessor: (row: any) => row.orderAddress?.address || "-",
						multiline: true,
						minWidth: 200,
					},
					{
						Header: Strings.orders.userAddress,
						id: "userAddress",
						accessor: (row: any) => row.userAddress?.address || "-",
						multiline: true,
						minWidth: 200,
					},
					{
						Header: Strings.orders.latitude,
						id: "latitude",
						accessor: (row: any) =>
							row.userAddress?.location?.lat?.toFixed(7) ||
							row.orderAddress?.location?.lat?.toFixed(7) ||
							"-",
						multiline: true,
						maxWidth: 115,
					},
					{
						Header: Strings.orders.longitude,
						id: "longitude",
						accessor: (row: any) =>
							row.userAddress?.location?.lng?.toFixed(7) ||
							row.orderAddress?.location?.lng?.toFixed(7) ||
							"-",
						multiline: true,
						maxWidth: 115,
					},
				);

				break;
			case "address_confirmation":
				defaultColumns.unshift({
					id: "selection",
					Header: () => {
						const totalEntries =
							addressesEntries?.type === "valid"
								? orders.filter(this.isValid)
								: orders.filter((ord: any) => !this.isValid(ord));

						return (
							<Checkbox
								checked={
									addressesEntries?.orders?.length > 0 &&
									addressesEntries?.orders?.length === totalEntries.length
								}
								onClick={() => {
									if (addressesEntries?.orders?.length === 0) {
										if (orders.filter(this.isValid)?.length === 0) {
											this.setState({
												addressesEntries: {
													type: "invalid",
													orders: orders
														.filter((ord: any) => !this.isValid(ord))
														.map((ord: any) => ord._id),
												},
											});
										} else {
											this.setState({
												addressesEntries: {
													type: "valid",
													orders: orders.filter(this.isValid).map((ord: any) => ord._id),
												},
											});
										}
									} else {
										if (addressesEntries?.type === "valid") {
											const entries = orders.filter(this.isValid);
											if (addressesEntries?.orders?.length !== entries.length) {
												this.setState({
													addressesEntries: {
														type: "valid",
														orders: orders.filter(this.isValid).map((ord: any) => ord._id),
													},
												});
											} else {
												this.setState({ addressesEntries: { type: "valid", orders: [] } });
											}
										} else {
											const entries = orders.filter((ord: any) => !this.isValid(ord));
											if (addressesEntries?.orders?.length !== entries.length) {
												this.setState({
													addressesEntries: {
														type: "invalid",
														orders: orders
															.filter((ord: any) => !this.isValid(ord))
															.map((ord: any) => ord._id),
													},
												});
											} else {
												this.setState({ addressesEntries: { type: "valid", orders: [] } });
											}
										}
									}
								}}
							/>
						);
					},
					Cell: (cell: any) => {
						const id = cell.row?.original?._id || null;
						const checked = addressesEntries?.orders?.includes(id) || false;
						const valid =
							addressesEntries?.orders?.length === 0 ||
							(addressesEntries?.type === "valid" && this.isValid(cell.row.original)) ||
							(addressesEntries?.type === "invalid" && !this.isValid(cell.row.original));

						return (
							<Checkbox
								checked={checked}
								disabled={!valid}
								onClick={() => {
									if (checked) {
										const index = addressesEntries?.orders?.indexOf(id);

										addressesEntries?.orders?.splice(index, 1);
										this.setState({ addressesEntries });
									} else if (valid) {
										if (addressesEntries?.orders?.length === 0) {
											addressesEntries.type = this.isValid(cell.row?.original)
												? "valid"
												: "invalid";
										}

										addressesEntries?.orders?.push(id);
										this.setState({ addressesEntries });
									}
								}}
							/>
						);
					},
					maxWidth: 35,
				});

				defaultColumns.push(
					{
						Header: Strings.orders.deliverySlot,
						id: "slot",
						accessor: (row: any) => {
							if (row.slot?.from && row.slot?.to) {
								return `${Duration.fromMillis(row.slot.from).toFormat("hh:mm")} - ${Duration.fromMillis(
									row.slot.to,
								).toFormat("hh:mm")}`;
							}

							return "-";
						},
					},
					{
						Header: "",
						id: "orderErrors",
						Cell: (cell: any) => {
							const orderErrors = cell.row?.original?.orderErrors || {};
							const title = [];

							if (orderErrors?.date) {
								title.push(<li key="date_error">{Strings.orders.validationDateError}</li>);
							}

							if (orderErrors?.address) {
								title.push(<li key="address_error">{Strings.orders.validationAddressError}</li>);
							}

							if (orderErrors?.postCode) {
								title.push(<li key="postcode_error">{Strings.orders.validationPostCodeError}</li>);
							}

							if (orderErrors?.coordinates) {
								title.push(
									<li key="coordinates_error">{Strings.orders.validationCoordinatesError}</li>,
								);
							}

							if (title.length > 0) {
								return (
									<Tooltip
										color={Variables.primaryColor}
										title={
											<ul className="TableOrderUnorderedList">
												{title.map((entry: any) => entry)}
											</ul>
										}
									>
										<div className="TableOrderStatus" />
									</Tooltip>
								);
							}

							return <div className="TableOrderStatus --order-confirmed" />;
						},
						maxWidth: 36,
					},
					{
						Header: Strings.orders.clientName,
						id: "user",
						accessor: (row: any) => row.user || "-",
					},
					{
						Header: Strings.orders.orderAddress,
						id: "orderAddress",
						accessor: (row: any) => row.orderAddress?.address || "-",
						multiline: true,
						minWidth: 200,
					},
					{
						Header: Strings.orders.userAddress,
						id: "userAddress",
						accessor: (row: any) => row.userAddress?.address || "-",
						multiline: true,
						minWidth: 200,
					},
					{
						Header: Strings.orders.latitude,
						id: "latitude",
						accessor: (row: any) =>
							row.userAddress?.location?.lat?.toFixed(7) ||
							row.orderAddress?.location?.lat?.toFixed(7) ||
							"-",
						multiline: true,
						maxWidth: 115,
					},
					{
						Header: Strings.orders.longitude,
						id: "longitude",
						accessor: (row: any) =>
							row.userAddress?.location?.lng?.toFixed(7) ||
							row.orderAddress?.location?.lng?.toFixed(7) ||
							"-",
						multiline: true,
						maxWidth: 115,
					},
				);

				break;
			case "picking":
				defaultColumns.unshift({
					id: "selection",
					Header: () => {
						return (
							<Checkbox
								checked={pickingEntries?.orders?.length === orders.length}
								onClick={() => {
									if (pickingEntries?.orders?.length > 0) {
										this.setState({
											pickingEntries: {
												orders: [],
											},
										});
									} else {
										this.setState({
											pickingEntries: {
												orders: orders.map((ord: any) => ord._id),
											},
										});
									}
								}}
							/>
						);
					},
					Cell: (cell: any) => {
						const id = cell.row?.original?._id || null;
						const checked = pickingEntries?.orders?.includes(id) || false;
						const valid =
							pickingEntries?.orders?.length === 0 ||
							(pickingEntries?.type === "valid" && this.isValid(cell.row.original)) ||
							(pickingEntries?.type === "invalid" && !this.isValid(cell.row.original));

						return (
							<Checkbox
								checked={checked}
								disabled={false}
								onClick={() => {
									if (checked) {
										const index = pickingEntries?.orders?.indexOf(id);

										pickingEntries?.orders?.splice(index, 1);
										this.setState({ pickingEntries });
									} else {
										pickingEntries?.orders?.push(id);
										this.setState({ pickingEntries });
									}
								}}
							/>
						);
					},
					maxWidth: 35,
				});

				defaultColumns.push(
					{
						Header: Strings.orders.deliverySlot,
						id: "slot",
						accessor: (row: any) => {
							if (row.slot?.from && row.slot?.to) {
								return `${Duration.fromMillis(row.slot.from).toFormat("hh:mm")} - ${Duration.fromMillis(
									row.slot.to,
								).toFormat("hh:mm")}`;
							}

							return "-";
						},
					},

					{
						Header: Strings.orders.clientName,
						id: "user",
						accessor: (row: any) => row.user || "-",
					},
					{
						Header: Strings.orders.orderAddress,
						id: "orderAddress",
						accessor: (row: any) => row.orderAddress?.address || "-",
						multiline: true,
						minWidth: 200,
					},
				);

				break;
			case "packing":
				const contractType = defaultContracts.find((cnt: any) => cnt._id === selectedContract)?.type?.type;

				let showCheckAllBox = orders?.find(
					(order: any) =>
						(!!order?.orderFlow?.bagId && !!order?.orderFlow?.bagWeight) ||
						order?.orderFlow?.boxes?.length > 0,
				);

				let ordersToSelection = orders.filter((order: any) => {
					return order?.orderFlow?.boxes?.length > 0 || (order?.orderFlow?.bagId && order?.orderFlow?.bagId);
				});

				console.log("🚀 ~ file: index.tsx:1538 ~ Orders ~ getTableColumns ~ orders:", orders);

				defaultColumns.unshift({
					id: "selection",
					Header: () => {
						let checked = packingEntries?.orders?.length === ordersToSelection.length;
						if (showCheckAllBox)
							return (
								<Checkbox
									checked={checked}
									onClick={() => {
										if (checked) {
											this.setState({
												packingEntries: {
													orders: [],
												},
											});
										} else {
											this.setState({
												packingEntries: {
													orders: ordersToSelection.map((ord: any) => ord),
												},
											});
										}
									}}
								/>
							);
						return null;
					},
					Cell: (cell: any) => {
						const id = cell.row?.original?._id || null;
						const index = packingEntries?.orders?.findIndex((order: any) => order._id === id);
						const checked = index !== -1;

						let actualOrder = ordersToSelection?.find((order: any) => order._id === id);
						let showCheckbox =
							actualOrder?.orderFlow?.boxes?.length > 0 ||
							(actualOrder?.orderFlow?.bagId && actualOrder?.orderFlow?.bagId);

						if (showCheckbox) {
							return (
								<Checkbox
									checked={checked}
									disabled={false}
									onClick={() => {
										if (checked) {
											if (index !== -1) {
												packingEntries?.orders?.splice(index, 1);
												this.setState({ packingEntries });
											}
										} else {
											packingEntries?.orders?.push(cell.row?.original);
											this.setState({ packingEntries });
										}
									}}
								/>
							);
						}
						return null;
					},
					maxWidth: 35,
				});

				defaultColumns.push(
					{
						Header: Strings.orders.deliverySlot,
						id: "deliverySlot",
						accessor: (row: any) => {
							if (row.slot?.from && row.slot?.to) {
								return `${Duration.fromMillis(row.slot?.from).toFormat(
									"hh:mm",
								)} - ${Duration.fromMillis(row.slot?.to).toFormat("hh:mm")}`;
							}

							return "-";
						},
					},
					{
						Header: Strings.orders.totalBoxes,
						id: "boxes",
						accessor: (row: any) => row?.orderFlow?.boxes?.length || "-",
					},
					{
						Header: Strings.orders.itemsQuantity,
						id: "itemsQuantity",
						accessor: (row: any) => (row?.itemsQuantity != null ? row.itemsQuantity : "-"),
						multiline: true,
					},
					{
						Header: Strings.orders.totalWeight,
						id: "totalWeight",
						accessor: (row: any) => {
							if (row.orderFlow?.bagId) {
								return row?.orderFlow?.bagWeight != null ? `${row.orderFlow?.bagWeight} Kg` : "-";
							}

							if (row.orderFlow?.boxes?.length > 0) {
								const weight = row.orderFlow?.boxes.reduce(
									(acc: number, cur: any) => acc + cur.weight,
									0,
								);
								return `${weight} Kg`;
							}

							return "-";
						},
					},
					{
						Header: Strings.orders.totalVolume,
						id: "totalVolume",
						accessor: (row: any) => (row?.totalWeight != null ? `${row.totalWeight} cm³` : "-"),
					},
				);

				if (contractType === "parcel") {
					defaultColumns.splice(4, 0, {
						Header: Strings.orders.bagId,
						id: "bagId",
						accessor: (row: any) => row?.orderFlow?.bagId || "-",
					});
				}

				break;
			case "aggregation":
				if (ordersToAggregate?.length > 0) {
					defaultColumns = [
						{
							Header: Strings.orders.box,
							id: "box",
							accessor: (row: any) => row?.boxName || "-",
						},
						{
							Header: Strings.orders.boxId,
							id: "box_id",
							accessor: (row: any) => row?.boxId || "N/D",
						},
						{
							Header: Strings.orders.weight,
							id: "box_weight",
							accessor: (row: any) => row?.boxWeight || "-",
						},
						{
							Header: Strings.orders.packing,
							id: "packing",
							Cell: (cell: any) => {
								let order = cell.row?.original;
								let checked = false;
								console.log("order for pack", order);
								let allTreated = order.bags.every(function (obj: any) {
									return obj.treated === true;
								});
								return (
									<Checkbox
										checked={checked}
										disabled={!allTreated}
										onClick={() => this.aggregate(order.boxId, false, order)}
									/>
								);
							},
						},
					];
				} else {
					const contractType = defaultContracts.find((cnt: any) => cnt._id === selectedContract)?.type?.type;

					let showCheckAllBox = orders?.find(
						(order: any) =>
							(!!order?.orderFlow?.bagId && !!order?.orderFlow?.bagWeight) ||
							order?.orderFlow?.boxes?.length > 0,
					);

					let ordersToSelection = orders.filter((order: any) => {
						return (
							order?.orderFlow?.boxes?.length > 0 || (order?.orderFlow?.bagId && order?.orderFlow?.bagId)
						);
					});

					console.log("🚀 ~ file: index.tsx:1538 ~ Orders ~ getTableColumns ~ orders:", orders);

					defaultColumns.unshift({
						id: "selection",
						Header: () => {
							let checked = aggregationEntries?.orders?.length === ordersToSelection.length;
							if (showCheckAllBox)
								return (
									<Checkbox
										checked={checked}
										onClick={() => {
											if (checked) {
												this.setState({
													aggregationEntries: {
														orders: [],
													},
												});
											} else {
												this.setState({
													aggregationEntries: {
														orders: ordersToSelection.map((ord: any) => ord),
													},
												});
											}
										}}
									/>
								);
							return null;
						},
						Cell: (cell: any) => {
							const id = cell.row?.original?._id || null;
							const index = aggregationEntries?.orders?.findIndex((order: any) => order._id === id);
							const checked = index !== -1;

							let actualOrder = ordersToSelection?.find((order: any) => order._id === id);
							let showCheckbox =
								actualOrder?.orderFlow?.boxes?.length > 0 ||
								(actualOrder?.orderFlow?.bagId && actualOrder?.orderFlow?.bagId);

							if (showCheckbox) {
								return (
									<Checkbox
										checked={checked}
										disabled={false}
										onClick={() => {
											if (checked) {
												if (index !== -1) {
													aggregationEntries?.orders?.splice(index, 1);
													this.setState({ aggregationEntries });
												}
											} else {
												aggregationEntries?.orders?.push(cell.row?.original);
												this.setState({ aggregationEntries });
											}
										}}
									/>
								);
							}
							return null;
						},
						maxWidth: 35,
					});

					defaultColumns.push(
						{
							Header: Strings.orders.deliverySlot,
							id: "deliverySlot",
							accessor: (row: any) => {
								if (row.slot?.from && row.slot?.to) {
									return `${moment.utc(row.slot?.from).format("hh:mm")} - ${moment
										.utc(row.slot?.to)
										.format("hh:mm")}`;
								}

								return "-";
							},
						},
						{
							Header: Strings.orders.totalBoxes,
							id: "boxes",
							accessor: (row: any) => row?.orderFlow?.boxes?.length || "-",
						},
						{
							Header: Strings.orders.itemsQuantity,
							id: "itemsQuantity",
							accessor: (row: any) => (row?.itemsQuantity != null ? row.itemsQuantity : "-"),
							multiline: true,
						},
						{
							Header: Strings.orders.totalWeight,
							id: "totalWeight",
							accessor: (row: any) => {
								if (row.orderFlow?.bagId) {
									return row?.orderFlow?.bagWeight != null ? `${row.orderFlow?.bagWeight} Kg` : "-";
								}

								if (row.orderFlow?.boxes?.length > 0) {
									const weight = row.orderFlow?.boxes.reduce(
										(acc: number, cur: any) => acc + cur.weight,
										0,
									);
									return `${weight} Kg`;
								}

								return "-";
							},
						},
						{
							Header: Strings.orders.totalVolume,
							id: "totalVolume",
							accessor: (row: any) => (row?.totalWeight != null ? `${row.totalWeight} cm³` : "-"),
						},
					);

					if (contractType === "parcel") {
						defaultColumns.splice(4, 0, {
							Header: Strings.orders.bagId,
							id: "bagId",
							accessor: (row: any) => row?.orderFlow?.bagId || "-",
						});
					}
				}

				break;
			case "loading":
				defaultColumns = [
					{
						Header: Strings.orders.route,
						id: "route_name",
						accessor: (row: any) => row.name || "N/D",
						multiline: true,
						minWidth: 150,
					},
					{
						Header: Strings.orders.grid,
						id: "route_grid_id",
						accessor: (row: any) => row.gridName || "N/D",
					},
					{
						Header: Strings.orders.readyToVan,
						id: "route_ready_to_van",
						Cell: (cell: any) => {
							let route = cell.row?.original;
							const { routesToVan } = this.state;
							let checked = routesToVan.includes(route);

							console.log("routesToVan", this.state.routesToVan);

							return (
								<Checkbox
									checked={checked}
									disabled={!route?.gridName}
									onClick={() => {
										if (!checked) {
											this.setState((prevState: any) => ({
												routesToVan: [...prevState.routesToVan, route],
											}));
										} else {
											this.setState((prevState: any) => ({
												routesToVan: prevState.routesToVan.filter(
													(item: any) => item._id !== route._id,
												),
											}));
										}
									}}
								/>
							);
						},
					},
				];

				break;
			case "ready_to_van":
				defaultColumns = [
					{
						Header: "Date",
						id: "route_date",
						accessor: (row: any) => {
							const dateObj = new Date(row?.date);
							const day = dateObj.getUTCDate().toString().padStart(2, "0");
							const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
							const year = dateObj.getUTCFullYear();

							const formattedDate = `${day}-${month}-${year}`;
							return row?.date ? formattedDate : "N/D";
						},
					},
					{
						Header: "Route",
						id: "route_name",
						accessor: (row: any) => row?.name || "N/D",
						multiline: true,
						minWidth: 150,
					},
					{
						Header: Strings.orders.grid,
						id: "route_grid",
						accessor: (row: any) => row.gridName || "N/D",
					},
					{
						Header: "Vehicle",
						id: "route_vehicle",
						accessor: (row: any) => row?.vehiclePlate || (row?.routeType === "bike" ? "--" : "N/D"),
					},

					{
						Header: Strings.orders.readyToDispatch,
						id: "route_ready_to_dispatch",
						Cell: (cell: any) => {
							let route = cell.row?.original;
							const { routesToDispatch } = this.state;
							let checked = routesToDispatch.includes(route);

							console.log("routesToVan", this.state.routesToVan);

							return (
								<Checkbox
									checked={checked}
									disabled={!route.vehiclePlate}
									onClick={() => {
										if (!checked) {
											this.setState((prevState: any) => ({
												routesToDispatch: [...prevState.routesToDispatch, route],
											}));
										} else {
											this.setState((prevState: any) => ({
												routesToDispatch: prevState.routesToDispatch.filter(
													(item: any) => item._id !== route._id,
												),
											}));
										}
									}}
								/>
							);
						},
					},
				];

				break;
			case "dispatching":
				defaultColumns = [
					{
						Header: Strings.orders.routeName,
						id: "name",
						accessor: (row: any) => row.name || "N/D",
						multiline: true,
						minWidth: 150,
					},
					{
						Header: Strings.orders.driver,
						id: "driver",
						accessor: (row: any) => row?.driverName || "N/D",
					},
					{
						Header: Strings.settings.vehiclePlate,
						id: "vehicle",
						accessor: (row: any) => row?.vehiclePlate || (row?.routeType === "bike" ? "--" : "N/D"),
					},
					{
						Header: Strings.orders.readyToDeliver,
						id: "route_ready_to_deliver",
						Cell: (cell: any) => {
							let route = cell.row?.original;
							const { routesToTransit } = this.state;
							let checked = routesToTransit.includes(route);

							console.log("routesToVan", this.state.routesToVan);

							return (
								<Checkbox
									checked={checked}
									disabled={!route.driverName}
									onClick={() => {
										if (!checked) {
											this.setState((prevState: any) => ({
												routesToTransit: [...prevState.routesToTransit, route],
											}));
										} else {
											this.setState((prevState: any) => ({
												routesToTransit: prevState.routesToTransit.filter(
													(item: any) => item._id !== route._id,
												),
											}));
										}
									}}
								/>
							);
						},
					},
				];

				break;
			case "on_transit":
				defaultColumns = [
					{
						Header: Strings.orders.routeName,
						id: "name",
						accessor: (row: any) => row.name || "N/D",
						multiline: true,
						minWidth: 150,
					},
					{
						Header: Strings.orders.driver,
						id: "driver",
						accessor: (row: any) => row?.driverName || "N/D",
					},
					{
						Header: Strings.settings.vehiclePlate,
						id: "vehicle",
						accessor: (row: any) => row?.vehiclePlate || (row?.routeType === "bike" ? "--" : "N/D"),
					},
					{
						Header: Strings.orders.progress,
						id: "progress",
						Cell: (cell: any) => {
							let route = cell.row?.original;
							console.log("🚀 ~ file: index.tsx:1920 ~ Orders ~ getTableColumns ~ route:", route);

							const deliveredAddresses = route.addresses.filter(
								(address: any) =>
									address?.order_id?.orderFlows[0]?.status === "delivered" &&
									address.address_stop_type?.toUpperCase() === "DELIVERY" &&
									!!address.order_id,
							);

							const nullOrderIdAddresses = route.addresses.filter(
								(address: any) =>
									address?.order_id == null ||
									address.address_stop_type?.toUpperCase() !== "DELIVERY",
							);

							let ordersDelivered =
								deliveredAddresses.length +
								"/" +
								(route.addresses.length - nullOrderIdAddresses.length);

							let percentage = Math.round(
								(deliveredAddresses.length * 100) /
									(route.addresses.length - nullOrderIdAddresses.length),
							);

							return (
								<Progress
									style={{ width: "90%" }}
									strokeLinecap={Variables.primaryColor}
									percent={percentage}
									format={() => `${ordersDelivered}`}
								/>
								// <Progress
								// 	strokeLinecap={Variables.primaryColor}
								// 	percent={50}
								// 	format={() => `${"2/4"}`}
								// />
								// <Progress
								// 	strokeLinecap={Variables.primaryColor}
								// 	percent={100}
								// 	format={() => `${"4/4"}`}
								// />
							);
						},
					},
				];

				break;

			case "delivered":
				defaultColumns = [
					{
						Header: Strings.orders.orderId,
						id: "order_id",
						accessor: (row: any) => {
							console.log("🚀 ~ file: index.tsx:2249 ~ Orders ~ getTableColumns ~ row:", row);
							return row.orderId || "N/D";
						},
						multiline: true,
						minWidth: 150,
					},
					{
						Header: Strings.orders.clientName,
						id: "client",
						accessor: (row: any) => row?.user || "N/D",
					},
					{
						Header: Strings.orders.deliveredDate,
						id: "delivered_date",
						accessor: (row: any) =>
							row?.orderFlow.deliveredDate
								? moment.utc(row?.orderFlow.deliveredDate).tz("Europe/Lisbon").format("DD/MM/YYYY HH:mm")
								: "-",
					},
					{
						Header: Strings.orders.driver,
						id: "driver",
						accessor: (row: any) => row?.orderFlow?.route?.driver || "N/D",
					},
				];

				break;
			case "not_delivered":
				defaultColumns = [
					{
						Header: Strings.orders.orderId,
						id: "order_id",
						accessor: (row: any) => {
							console.log("🚀 ~ file: index.tsx:2249 ~ Orders ~ getTableColumns ~ row:", row);
							return row.orderId || "N/D";
						},
						multiline: true,
						minWidth: 150,
					},
					{
						Header: Strings.orders.clientName,
						id: "client",
						accessor: (row: any) => row?.user || "N/D",
					},
					{
						Header: Strings.orders.deliveredDate,
						id: "delivered_date",
						accessor: (row: any) =>
							row?.orderFlow.deliveredDate
								? moment.utc(row?.orderFlow.deliveredDate).tz("Europe/Lisbon").format("DD/MM/YYYY HH:mm")
								: "-",
					},
					{
						Header: Strings.orders.driver,
						id: "driver",
						accessor: (row: any) => row?.orderFlow?.route?.driver || "N/D",
					},
				];

				break;
			case "cancelled":
				defaultColumns = [
					{
						Header: Strings.orders.orderId,
						id: "order_id",
						accessor: (row: any) => {
							console.log("🚀 ~ file: index.tsx:2249 ~ Orders ~ getTableColumns ~ row:", row);
							return row.orderId || "N/D";
						},
						multiline: true,
						minWidth: 150,
					},
					{
						Header: Strings.orders.clientName,
						id: "client",
						accessor: (row: any) => row?.user || "N/D",
					},
					{
						Header: Strings.orders.driver,
						id: "driver",
						accessor: (row: any) => row?.orderFlow?.route?.driver || "N/D",
					},
				];

				break;
			case "dispute":
				defaultColumns = [
					{
						Header: Strings.orders.orderId,
						id: "order_id",
						accessor: (row: any) => {
							console.log("🚀 ~ file: index.tsx:2249 ~ Orders ~ getTableColumns ~ row:", row);
							return row.orderId || "N/D";
						},
						multiline: true,
						minWidth: 150,
					},
					{
						Header: Strings.orders.clientName,
						id: "client",
						accessor: (row: any) => row?.user || "N/D",
					},
					{
						Header: Strings.orders.driver,
						id: "driver",
						accessor: (row: any) => row?.orderFlow?.route?.driver || "N/D",
					},
				];

				break;
			default:
				break;
		}

		return defaultColumns;
	}

	handleBoxIdChange = (e: any) => {
		const value = e.target.value;
		this.setState({
			boxIdInAggregation: value,
		});
	};

	handleConfirmOnAggregationDrawer(box: any, localValue: any) {
		this.aggregate(localValue, true, box);
		this.setState({ showDrawer: false });
	}

	handlePackOnAggregation(box: any) {
		let bagsToAggregate; //need to put on state to then pack and then when we finnish we show the order and not the orders to aggregate
		let auxArray = JSON.parse(JSON.stringify(bagsToAggregate));
		let updatedArray: any[] = [];
		auxArray.map((itemAux: any) => {
			if (itemAux._id == box._id) {
				console.log("update");
				itemAux.selected = !itemAux.selected;
			}
			updatedArray.push(itemAux);
		});
		this.setState({ updatedArray });
		this.aggregate("", false, box);
		//onCallUpdateOrdersAggregation(box, updatedArray, false);
	}

	getBagsTableColumns() {
		let defaultColumns: any = [
			{
				Header: Strings.orders.bagId,
				id: "orderId",
				//accessor: (row: any) => row?.bagId
				Cell: (cell: any) => {
					let bag = cell.row?.original;
					return (
						<Input
							id="drawer_bag_id"
							placeholder={Strings.orders.bagId}
							value={bag?.bagId || ""}
							readOnly
							//onChange={(e: string) => this.setState({boxIdInAggregation: e })}
						/>
					);
				},
			},
			{
				Header: Strings.orders.weight,
				id: "bag_weight",
				//accessor: (row: any) => row?.bagWeight
				Cell: (cell: any) => {
					let bag = cell.row?.original;
					return (
						<Input
							id="drawer_bag_weight"
							placeholder={Strings.orders.weight}
							value={bag?.bagWeight || ""}
							readOnly
							onChange={(e: any) => {}}
						/>
					);
				},
			},
			{
				Header: Strings.orders.boxId,
				id: "box_id",
				Cell: (cell: any) => {
					let box = this.state.ordersToAggregate.find(
						(box: any) => cell?.row?.original?.boxOrderId === box?._id,
					);

					return (
						<Input
							value={box.boxId || "N/D"}
							onFocus={() =>
								this.setState({
									rowToChange: box,
									newBoxIdAggregation: box.boxId,
									showDrawer: true,
								})
							}
						/>
					);
				},
			},

			{
				Header: "Treated",
				id: "treated",
				Cell: (cell: any) => {
					let bag = cell.row.original;
					console.log("bag", cell.row.original);
					let box = this.state.ordersToAggregate.find((box: any) => bag.boxOrderId === box?._id);
					console.log("box.boxOrderId", box);
					return (
						<Checkbox
							checked={bag?.treated}
							disabled={!box?.boxId}
							onClick={() => {
								if (box._id) {
									this.updateBag(bag.orderId, bag.flowId, !bag?.treated);
								} else {
									notification.warn({
										message: Strings.sidebar.orders,
										description: Strings.orders.fillBagFields,
										placement: "bottomRight",
										duration: 5,
									});
								}
							}}
						/>
					);
				},
			},

			// {
			// 	Header: Strings.orders.deliveryDate,
			// 	id: "deliveryDate",
			// 	accessor: (row: any) =>
			// 		(row.orderFlow?.deliveryDate && moment.utc(row.orderFlow.deliveryDate).format("DD-MM-YYYY")) || "-"
			// }
		];

		return defaultColumns;
	}

	getLoadingRoutesColumns() {
		let defaultColumns: any = [
			{
				Header: Strings.orders.boxId,
				id: "routes_box_id",
				//accessor: (row: any) => row?.bagId
				Cell: (cell: any) => {
					let value = cell?.row?.original?.boxId;

					return <Input readOnly value={value} onFocus={() => {}} />;
				},
			},
			{
				Header: Strings.orders.line,
				id: "routes_line",
				//accessor: (row: any) => row?.bagWeight
				Cell: (cell: any) => {
					let value = cell?.row?.original?.line;

					return <Input readOnly value={value} onFocus={() => {}} />;
				},
			},
			{
				Header: Strings.orders.column,
				id: "routes_column",
				Cell: (cell: any) => {
					let value = cell?.row?.original?.column;

					return <Input readOnly value={value} onFocus={() => {}} />;
				},
			},

			{
				Header: Strings.orders.depth,
				id: "routes_depth",
				Cell: (cell: any) => {
					let value = cell?.row?.original?.depth;

					return <Input readOnly value={value} onFocus={() => {}} />;
				},
			},
		];

		return defaultColumns;
	}

	getDeliveryRoutesColumns() {
		let defaultColumns: any = [
			{
				Header: Strings.orders.orderId,
				id: "routes_order_id",
				accessor: (row: any) =>
					row?.order_id
						? String(row?.order_id?.orderId?.toString().padStart(10, "0"))
						: row?.is_depot
						? Strings.orders.warehouse
						: "--",
			},
			{
				Header: Strings.orders.address,
				id: "routes_address",
				accessor: (row: any) => row?.address,
			},
			{
				Header: Strings.orders.stopType,
				id: "routes_stop_type",
				accessor: (row: any) => row?.address_stop_type?.toUpperCase() || "-",
			},
			{
				Header: "ETA",
				id: "routes_eta",
				accessor: (row: any) =>
					row?.estimatedTimeArrival ? moment.utc(row?.estimatedTimeArrival).format("HH:mm") : "-",
			},
			{
				Header: Strings.orders.deliveryDate,
				id: "routes_delivery_date",
				accessor: (row: any) => (row?.deliveryDate ? moment.utc(row?.deliveryDate).tz("Europe/Lisbon").format("DD/MM/YYYY") : "-"),
			},
			{
				Header: Strings.orders.slot,
				id: "routes_slot",
				accessor: (row: any) =>
					row?.orderSlot?.from && row?.orderSlot?.to
						? moment.utc(row?.orderSlot?.from).format("HH:mm") +
						  " - " +
						  moment.utc(row?.orderSlot?.to).format("HH:mm")
						: "-",
			},
			{
				Header: Strings.orders.deliveredDate,
				id: "routes_delivered_date",
				accessor: (row: any) =>
					row?.order_id?.orderFlows[0]?.status === "delivered" && row?.order_id?.orderFlows[0]?.deliveredDate
						? moment.utc(row?.order_id?.orderFlows[0].deliveredDate).tz("Europe/Lisbon").format("DD/MM/YYYY HH:mm")
						: "-",
			},
			{
				Header: Strings.orders.status,
				id: "routes_delivered_status",
				Cell: (cell: any) => {
					console.log(
						"🚀 ~ file: index.tsx:2451 ~ Orders ~ getDeliveryRoutesColumns ~ row:",
						cell.row.original,
					);
					let row = cell.row.original;
					if (!row?.order_id || row?.address_stop_type?.toUpperCase() !== "DELIVERY") return "--";
					return (
						<div
							style={{
								color:
									row?.order_id?.orderFlows[0]?.status === "delivered"
										? "green"
										: row?.order_id?.orderFlows[0]?.status === "delivered" ||
										  row?.order_id?.orderFlows[0]?.status === "cancelled"
										? "red"
										: "black",
								justifyContent: "center",
							}}
						>
							{this.getStatusName(row?.order_id?.orderFlows[0]?.status)}
						</div>
					);
				},
			},
		];

		return defaultColumns;
	}

	getBoxesColumns() {
		let defaultColumns: any = [
			{
				Header: "",
				id: "route_box_id",
				accessor: (row: any) => row?.boxId,
			},
			{
				Header: "",
				id: "route_bag_id",
				accessor: (row: any) => row?.bagId,
			},
		];
		console.log("🚀 ~ file: index.tsx:2323 ~ Orders ~ getBoxesColumns ~ defaultColumns:", defaultColumns);

		return defaultColumns;
	}

	getTableHeaderOptions() {
		const {
			preventCall,
			activeTab,
			selectedBusiness,
			selectedContract,
			addressesEntries,
			pickingEntries,
			packingEntries,
			routesToVan,
			routesToTransit,
			routesToDispatch,
			aggregationEntries,
		} = this.state;

		const headerOptions = [
			{
				icon: "sync",
				tooltip: Strings.orders.refresh,
				disabled: preventCall,
				noText: true,
				onClick: () => {
					if (!preventCall) {
						this.getData();

						this.setState({ preventCall: true }, () => {
							clearTimeout(this.preventRefresh);
							this.preventRefresh = setTimeout(() => this.setState({ preventCall: false }), 2000);
						});
					}
				},
			},
		] as any;

		switch (activeTab) {
			case "registered":
				headerOptions.unshift({
					label: "Registar",
					tooltip:
						!selectedBusiness || !selectedContract ? Strings.orders.pleaseSelectBoth : "Registar Encomenda",
					disabled: !selectedBusiness || !selectedContract,
					icon: "adicionar-acrescentar",
					onClick: () => window.open(`/orders/new`, "_blank"),
				});
				headerOptions.unshift({
					label: Strings.generic.import,
					tooltip:
						!selectedBusiness || !selectedContract
							? Strings.orders.pleaseSelectBoth
							: Strings.generic.import,
					disabled: !selectedBusiness || !selectedContract,
					icon: "upload-folder",
					onClick: () => this.setState({ showImportModal: true }),
				});

				break;
			case "address_confirmation":
				if (addressesEntries?.type === "valid" && addressesEntries?.orders?.length > 0) {
					headerOptions.unshift({
						type: "button",
						label: Strings.orders.definePicking,
						onClick: () => {
							this.patchAddressesOrders();
						},
					});
				} else if (addressesEntries?.type === "invalid" && addressesEntries?.orders?.length > 0) {
					headerOptions.unshift({
						type: "button",
						label: Strings.orders.defineAddress,
						onClick: () => {
							this.patchAddresses();
						},
					});
				}

				break;
			case "picking":
				if (pickingEntries?.orders?.length > 0) {
					console.log(
						"🚀 ~ file: index.tsx:2516 ~ Orders ~ getTableHeaderOptions ~ pickingEntries:",
						pickingEntries,
					);

					headerOptions.unshift({
						type: "button",
						label: Strings.orders.moveToPacking,
						onClick: () => this.moveToPacking(),
					});
				}

				break;
			case "packing":
				let canAggregate = packingEntries?.orders?.find(
					(order: any) => !!order?.orderFlow?.bagId && !!order?.orderFlow?.bagWeight,
				);
				if (packingEntries?.orders?.length > 0) {
					console.log("packingEntries", packingEntries);
					headerOptions.unshift({
						type: "button",
						label: canAggregate ? Strings.orders.aggregateBags : Strings.orders.savePacking,
						onClick: () => this.packBags(packingEntries),
					});
				}

				headerOptions.unshift({
					label: Strings.generic.import,
					tooltip:
						!selectedBusiness || !selectedContract
							? Strings.orders.pleaseSelectBoth
							: Strings.generic.import,
					disabled: !selectedBusiness || !selectedContract,
					icon: "upload-folder",
					onClick: () => this.setState({ showImportModal: true }),
				});

				break;
			case "aggregation":
				if (aggregationEntries?.orders?.length > 0) {
					console.log("packingEntries", aggregationEntries);
					headerOptions.unshift({
						type: "button",
						label: "Finish Aggregation",
						onClick: () => this.packBags(aggregationEntries, true),
					});
				}

				break;
			case "loading":
				if (routesToVan.length > 0) {
					headerOptions.unshift({
						type: "button",
						label: Strings.orders.moveToReadyToVan,
						onClick: () => this.moveToNextStep("loading"),
					});
				}

				break;
			case "ready_to_van":
				if (routesToDispatch.length > 0) {
					headerOptions.unshift({
						type: "button",
						label: Strings.orders.moveToDispatching,
						onClick: () => this.moveToNextStep("ready_to_van"),
					});
				}

				break;
			case "dispatching":
				if (routesToTransit.length > 0) {
					headerOptions.unshift({
						type: "button",
						label: Strings.orders.moveToOnTransit,
						onClick: () => this.moveToNextStep("dispatching"),
					});
				}

				break;
			default:
				break;
		}

		return headerOptions;
	}

	getTableActions() {
		const { activeTab, defaultContracts, selectedContract } = this.state;
		const { dispatch } = this.props;

		let actions = {} as any;

		switch (activeTab) {
			case "registered":
			case "address_confirmation":
				actions.edit = (row: any) => ({
					onClick: () => dispatch(push(`/orders/${row._id}`)),
				});

				break;
			case "picking":
				actions.view = (row: any) => ({
					onClick: () => window.open(`/orders/${row._id}`, "_blank"),
				});

				break;
			case "packing":
				const contractType = defaultContracts.find((cnt: any) => cnt._id === selectedContract)?.type?.type;

				actions.remove = (row: any) => ({
					onClick: () => {
						this.archiveOrder(row._id);
					},
				});

				actions.edit = (row: any) => ({
					onClick: () => {
						const tempOrder = JSON.parse(JSON.stringify(row));

						if (contractType === "food" || contractType === "pharmacy") {
							if (
								!Array.isArray(tempOrder?.orderFlow?.boxes) ||
								tempOrder?.orderFlow?.boxes?.length < 1
							) {
								tempOrder.orderFlow.boxes = [{ boxId: "", weight: null }];
							}
						}

						this.setState({
							showDrawer: true,
							drawerType: "picking",
							tempOrder,
							packingType:
								contractType === "food" || contractType === "pharmacy"
									? "box"
									: tempOrder.orderFlow?.bagId || tempOrder.orderFlow?.boxes?.length === 0
									? "bag"
									: "box",
						});
					},
				});

				actions.view = (row: any) => ({
					onClick: () => window.open(`/orders/${row._id}`, "_blank"),
				});

				break;
			case "loading":
				actions.edit = (row: any) => ({
					onClick: () => {
						console.log("row on actions", row);
						this.setState({
							selectedRoute: row,
							selectedRouteGrid: row.gridName,
							showDrawer: true,
						});
					},
				});

				actions.exportExcel = (row: any) => ({
					onClick: () => {
						console.log("row on actions", row);
						this.downloadRouteCsv(row);
					},
				});

				break;
			case "ready_to_van":
				actions.edit = (row: any) => ({
					onClick: () => {
						console.log("row on actions", row);
						this.setState({
							selectedRoute: row,
							selectedRouteGrid: row.vehiclePlate,
							showDrawer: true,
						});
					},
				});

				break;
			case "dispatching":
				actions.edit = (row: any) => ({
					onClick: () => {
						console.log("row on actions", row);
						this.setState({
							selectedRoute: row,
							selectedRouteDriver: row?.driverName,
							showDrawer: true,
						});
					},
				});

				actions.exportExcel = (row: any) => ({
					onClick: () => {
						console.log("row on actions", row);
						this.downloadRouteCsv(row);
					},
				});

				break;
			case "on_transit":
				actions.edit = (row: any) => ({
					onClick: () => {
						console.log("row on actions", row);
						this.setState({
							selectedRoute: row,
							selectedRouteDriver: row?.driverName,
							showDrawer: true,
						});
					},
				});

				actions.exportExcel = (row: any) => ({
					onClick: () => {
						console.log("row on actions", row);
						this.downloadRouteCsv(row);
					},
				});

				break;
			case "delivered":
				actions.view = (row: any) => ({
					onClick: () => window.open(`/orders/${row._id}`, "_blank"),
				});
				actions.edit = (row: any) => ({
					onClick: () => {
						console.log("123456", row);
						const tempOrder = JSON.parse(JSON.stringify(row));
						this.setState({
							selectedOrderId: row._id,
							selectedOrder: row,
							isOrderInDeliverySteps: true,
							tempOrder: {
								...tempOrder,
								orderFlow: {
									...tempOrder.orderFlow,
									status: row.orderFlow.status,
									deliveredDate: moment.utc(row.order_id?.orderFlows.deliveredDate),
								},
							},
							showDrawer: true,
						});
					},
				});

				break;
			case "not_delivered":
				actions.view = (row: any) => ({
					onClick: () => window.open(`/orders/${row._id}`, "_blank"),
				});
				actions.edit = (row: any) => ({
					onClick: () => {
						console.log("123456", row);
						const tempOrder = JSON.parse(JSON.stringify(row));
						this.setState({
							selectedOrderId: row._id,
							selectedOrder: row._id,
							isOrderInDeliverySteps: true,
							tempOrder: {
								...tempOrder,
								orderFlow: {
									...tempOrder.orderFlow,
									status: row.orderFlow.status,
									deliveredDate: moment.utc(row.order_id?.orderFlows.deliveredDate),
								},
							},
							showDrawer: true,
						});
					},
				});

				break;
			case "cancelled":
				actions.view = (row: any) => ({
					onClick: () => window.open(`/orders/${row._id}`, "_blank"),
				});
				actions.edit = (row: any) => ({
					onClick: () => {
						console.log("123456", row);
						const tempOrder = JSON.parse(JSON.stringify(row));
						this.setState({
							selectedOrderId: row._id,
							selectedOrder: row._id,
							isOrderInDeliverySteps: true,
							tempOrder: {
								...tempOrder,
								orderFlow: {
									...tempOrder.orderFlow,
									status: row.orderFlow.status,
									deliveredDate: moment.utc(row.order_id?.orderFlows.deliveredDate),
								},
							},
							showDrawer: true,
						});
					},
				});

				break;
			case "dispute":
				actions.view = (row: any) => ({
					onClick: () => window.open(`/orders/${row._id}`, "_blank"),
				});
				actions.edit = (row: any) => ({
					onClick: () => {
						console.log("123456", row);
						const tempOrder = JSON.parse(JSON.stringify(row));
						this.setState({
							selectedOrderId: row._id,
							selectedOrder: row._id,
							isOrderInDeliverySteps: true,
							tempOrder: {
								...tempOrder,
								orderFlow: {
									...tempOrder.orderFlow,
									status: row.orderFlow.status,
									deliveredDate: moment.utc(row.order_id?.orderFlows.deliveredDate),
								},
							},
							showDrawer: true,
						});
					},
				});

				break;
			default:
				actions = null;
				break;
		}

		return actions;
	}

	getOnTransitTableActions() {
		let actions = {} as any;

		if (this.state.activeTab === "dispatching") {
			actions.view = (row: any) => ({
				onClick: () => window.open(`/orders/${row.order_id._id}`, "_blank"),
			});
		} else {
			actions.edit = (row: any) => ({
				onClick: () => {
					console.log("🚀 ~ file: index.tsx:2786 ~ Orders ~ getOnTransitTableActions ~ row:", row.order_id);
					const tempOrder = JSON.parse(JSON.stringify(row));
					this.setState({
						selectedOrderId: row.order_id._id,
						selectedOrder: row.order_id,
						isOrderInDeliverySteps: true,
						tempOrder: {
							...tempOrder,
							orderFlow: {
								...tempOrder.orderFlow,
								status: row.order_id?.orderFlows?.[0]?.status,
								deliveredDate: moment.utc(row.order_id?.orderFlows?.[0]?.deliveredDate),
							},
						},
						showDrawer: true,
					});
				},
				hide: row?.address_stop_type?.toUpperCase() !== "DELIVERY",
			});

			actions.view = (row: any) => ({
				onClick: () => window.open(`/orders/${row.order_id._id}`, "_blank"),
			});
		}

		return actions;
	}

	renderModal() {
		const { showImportModal, file, uploadDate, shiftSelected, shifts } = this.state;
		console.log("🚀 ~ file: index.tsx:2661 ~ Orders ~ renderModal ~ shifts:", shifts);
		const { mobile } = this.props;
		let size: any;

		if (file) {
			size = this.humanFileSize(file.size, true);
		}

		return (
			<Modal
				centered={true}
				closable={true}
				onCancel={() => this.setState({ showImportModal: false })}
				visible={showImportModal}
				footer={null}
				bodyStyle={{ padding: 0 }}
				width={mobile ? "100%" : 600}
			>
				<div className="UploadModal">
					<div className="UploadTitle">
						<p>{Strings.orders.upload}</p>
					</div>
					<div className="shiftInfo">
						<div className="InlineGroup">
							<label htmlFor="upload_modal_date" className="InputLabel --label-required">
								{Strings.fields.date + ":"}
							</label>
							<DatePicker
								value={uploadDate}
								onChange={(localValue: any) => {
									this.setState({
										uploadDate: localValue,
									});
								}}
								format={"DD/MM/YYYY"}
								style={{ width: "150px" }}
							/>
						</div>
						<div className="InlineGroup">
							<label htmlFor="upload_modal_shift" className="InputLabel --label-required">
								{Strings.fields.shift + ":"}
							</label>
							<Select
								//className="shift_select"
								style={{ width: "150px" }}
								placeholder={Strings.fields.shift}
								//showSearch
								showArrow={true}
								disabled={false}
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								value={shiftSelected}
								id="drawer_packaging_ype"
								onChange={(elem: any) => {
									this.setState({ selectedShift: elem });
								}}
							>
								{shifts?.map((elem: any) => {
									return <Select.Option value={elem._id}>{elem.name}</Select.Option>;
								})}
							</Select>
						</div>
					</div>
					<div className="UploadBody">
						<Dropzone
							className="UploadDropzone"
							id="upload_file"
							multiple={false}
							accept="text/csv, .xls, .xlsx"
							onDrop={(files: any) => this.setState({ file: files[0] })}
						>
							{file ? (
								<React.Fragment>
									<p>Name: {file?.name}</p>
									<p>Size: {size}</p>
								</React.Fragment>
							) : (
								<React.Fragment>
									<Icons.UploadFile />
									<p>{Strings.orders.uploadFile}</p>
								</React.Fragment>
							)}
						</Dropzone>
					</div>
					<div className="UploadFooter">
						<form
							method="get"
							// temporary
							action="https://dingoo-dev.s3.eu-central-1.amazonaws.com/dev/files/importacao_encomendas.xlsx"
						>
							<button className="DownloadButton" onClick={() => {}}>
								{Strings.orders.download}
							</button>
						</form>
						<button
							className="ImportButton"
							onClick={() => {
								// if (shiftStart > shiftEnd) {
								// 	return notification.warn({
								// 		message: Strings.sidebar.orders,
								// 		description: Strings.errors.shiftDateError,
								// 		placement: "bottomRight",
								// 		duration: 5,
								// 	});
								//} else {
								this.uploadFile();
								//}
							}}
							disabled={!file || !uploadDate}
						>
							{Strings.orders.import}
						</button>
					</div>
				</div>
			</Modal>
		);
	}

	renderImportBoxesModal() {
		const { showImportModal, file } = this.state;
		const { mobile } = this.props;
		let size: any;

		if (file) {
			size = this.humanFileSize(file.size, true);
		}

		return (
			<Modal
				centered={true}
				closable={true}
				onCancel={() => this.setState({ showImportModal: false })}
				visible={showImportModal}
				footer={null}
				bodyStyle={{ padding: 0 }}
				width={mobile ? "100%" : 600}
			>
				<div className="UploadModal">
					<div className="UploadTitle">
						<p>{"Importar Caixas"}</p>
					</div>
					<div className="UploadBody">
						<Dropzone
							className="UploadDropzone"
							id="upload_file"
							multiple={false}
							accept="text/csv, .xls, .xlsx"
							onDrop={(files: any) => this.setState({ file: files[0] })}
						>
							{file ? (
								<React.Fragment>
									<p>Name: {file?.name}</p>
									<p>Size: {size}</p>
								</React.Fragment>
							) : (
								<React.Fragment>
									<Icons.UploadFile />
									<p>{Strings.orders.uploadFile}</p>
								</React.Fragment>
							)}
						</Dropzone>
					</div>
					<div className="UploadFooter">
						<form
							method="get"
							// temporary
							action="https://dingoo-dev.s3.eu-central-1.amazonaws.com/dev/files/importacao_caixas.xlsx"
						>
							<button className="DownloadButton" onClick={() => {}}>
								{Strings.orders.download}
							</button>
						</form>
						<button
							className="ImportButton"
							onClick={() => {
								// if (shiftStart > shiftEnd) {
								// 	return notification.warn({
								// 		message: Strings.sidebar.orders,
								// 		description: Strings.errors.shiftDateError,
								// 		placement: "bottomRight",
								// 		duration: 5,
								// 	});
								//} else {
								this.uploadFile();
								//}
							}}
							disabled={!file}
						>
							{Strings.orders.import}
						</button>
					</div>
				</div>
			</Modal>
		);
	}

	renderInvalidOrdersModal() {
		const { showInvalidOrdersModal, invalidOrders } = this.state;
		const { mobile } = this.props;

		return (
			<Modal
				centered={true}
				closable={true}
				onCancel={() =>
					this.setState({ showInvalidOrdersModal: false, invalidOrders: [] }, () => this.getData())
				}
				visible={showInvalidOrdersModal}
				footer={null}
				bodyStyle={{ padding: 0 }}
				width={mobile ? "100%" : 800}
			>
				<div className="UploadModal">
					<div className="UploadTitle">
						<p>{Strings.orders.importedErrors}</p>
					</div>
					<table className="UploadBody --boxed">
						<thead className="UploadHeaders">
							<tr>
								<th>{Strings.orders.orderId}</th>
								<th>{Strings.orders.clientName}</th>
								<th>{Strings.orders.orderDate}</th>
								<th>{Strings.generic.error}</th>
							</tr>
						</thead>
						<tbody className="UploadErrorList">
							{invalidOrders.map((order: any, index: number) => (
								<tr key={`invalid_order_${index}`} className="InvalidOrderEntry">
									<td>{order.OrderID || "-"}</td>
									<td>{order.ClientName || "-"}</td>
									<td>
										{order.DeliveryDate
											? moment(order.DeliveryDate, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY")
											: "-"}
									</td>
									<td>
										<span>
											{this.getError(order.error)}
											{order.error === "missing_fields" ? <sup>1</sup> : ""}.
										</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="UploadInfo">
						<small>
							1. The required fields are:{" "}
							<span>
								<code>OrderID</code>, <code>OrderDate</code>, <code>DeliveryDate</code>,{" "}
								<code>From</code>, <code>To</code>, <code>Address</code>, <code>ClientName</code> and{" "}
								<code>Email</code>.
							</span>
						</small>
					</div>
					<div className="UploadFooter">
						<button
							className="DownloadButton"
							onClick={() =>
								this.setState({ showInvalidOrdersModal: false, invalidOrders: [] }, () =>
									this.getData(),
								)
							}
						>
							{Strings.generic.confirm}
						</button>
					</div>
				</div>
			</Modal>
		);
	}

	renderAggregationBagInfo(box: any) {
		console.log("box", box);
		const columns: Array<any> = this.getBagsTableColumns();
		return <Table title={""} data={box?.original?.bags} columns={columns} selectable paginated={false} />;
	}

	renderLoadingInfo(route: any) {
		const { activeTab } = this.state;
		console.log("route in loading info", route);

		const tableProps: any = {};

		let actions: any = this.getOnTransitTableActions();

		const columns: Array<any> =
			activeTab === "loading" ? this.getLoadingRoutesColumns() : this.getDeliveryRoutesColumns();

		let tableData = activeTab === "loading" ? route.original?.gridLoadSequence : route.original?.addresses;

		let expand = activeTab === "on_transit" || activeTab === "dispatching";
		let hasActions = activeTab === "on_transit" || activeTab === "dispatching";

		console.log("activeTab", activeTab);

		if (expand !== null && expand !== undefined) {
			tableProps.expanded = expand;
		}

		if (hasActions) {
			tableProps.actions = actions;
		}
		return (
			<Table
				title={""}
				data={tableData}
				columns={columns}
				key={this.state.expandedOrderInRoute}
				outOfFormatBoxes={activeTab === "loading" ? route?.original?.boxesOutOfFormat : []}
				outOfRouteBoxes={activeTab === "loading" ? route?.original?.unRoutedBoxes : []}
				selectable
				paginated={false}
				onTransitActions={hasActions}
				onTransitOrder
				{...tableProps}
				//alwaysExpanded
				renderExpanded={(item: any) =>
					item?.original?.boxes?.length > 0 && (
						<div className="boxContainer">{this.renderBoxesOnTransit(item?.original?.boxes, true)}</div>
					)
				}
			/>
		);
	}

	renderBoxesOnTransit = (boxes: any, isOnTransit = false) => {
		console.log("🚀 ~ file: index.tsx:3145 ~ Orders ~ boxes:", boxes);
		// let boxesTest = [
		// 	{ boxId: "123456" },
		// 	{ boxId: "123456" },
		// 	{ boxId: "123456" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// 	{ boxId: "123456", bagId: "7364536" },
		// ];
		if (isOnTransit)
			return (
				<div style={{ display: "flex", flexWrap: "wrap", marginLeft: "40px", paddingBlock: "20px" }}>
					{boxes?.map((item: any, index: any) => (
						<div key={item?.boxId} style={{ whiteSpace: "nowrap" }}>
							{item.bagId ? (
								index === boxes.length - 1 ? (
									<p>{`${item?.boxId} - ${Strings.orders.bag} ${item?.bagId}`}</p>
								) : (
									<p>{`${item?.boxId} - ${Strings.orders.bag} ${item?.bagId};\u00A0`}</p>
								)
							) : index === boxes.length - 1 ? (
								<p>{`${item?.boxId}`}</p>
							) : (
								<p>{`${item?.boxId};\u00A0`}</p>
							)}
						</div>
					))}
				</div>
			);

		return (
			<div style={{ display: "flex", flexWrap: "wrap", marginLeft: "40px", paddingBlock: "20px" }}>
				{boxes?.map((item: any, index: any) => (
					<div key={item?.boxId} style={{ whiteSpace: "nowrap" }}>
						{item.bagId ? (
							index === boxes.length - 1 ? (
								<p>{`${item?.id} - ${Strings.orders.bag} ${item?.bagId}`}</p>
							) : (
								<p>{`${item?.id} - ${Strings.orders.bag} ${item?.bagId};\u00A0`}</p>
							)
						) : index === boxes.length - 1 ? (
							<p>{`${item?.id}`}</p>
						) : (
							<p>{`${item?.id};\u00A0`}</p>
						)}
					</div>
				))}
			</div>
		);
	};

	renderTable() {
		const {
			orders = [],
			selectedBusiness,
			selectedContract,
			selectedDate,
			activeTab,
			ordersToAggregate,
		} = this.state;

		const tableProps: any = {};

		let emptyOrdersToAggregate = ordersToAggregate?.length === 0;
		console.log("orders", orders);

		if (activeTab !== "registered" && activeTab !== "address_confirmation") {
			if (!selectedBusiness && !selectedContract) {
				tableProps.emptyDescription = <span>{Strings.orders.pleaseSelectBoth}</span>;
			} else if (!selectedBusiness) {
				tableProps.emptyDescription = <span>{Strings.orders.pleaseSelectBusiness}</span>;
			} else if (!selectedContract) {
				tableProps.emptyDescription = <span>{Strings.orders.pleaseSelectContract}</span>;
			} else if (!selectedDate) {
				tableProps.emptyDescription = <span>{Strings.orders.pleaseSelectDate}</span>;
			}
		}

		let title = Strings.sidebar.orders;
		let icon = "order";
		const columns: Array<any> = this.getTableColumns();
		let actions: any = this.getTableActions();

		if (activeTab === "loading" || activeTab === "on_transit") {
			title = Strings.orders.routes;
			icon = "route";
		}

		let expand = activeTab === "aggregation" && !emptyOrdersToAggregate;

		console.log("activeTab", activeTab);

		if (expand !== null && expand !== undefined) {
			tableProps.expanded = expand;
		}

		if (
			activeTab === "registered" ||
			activeTab === "address_confirmation" ||
			activeTab === "picking" ||
			activeTab === "packing" ||
			activeTab === "aggregation"
		) {
			return (
				<Table
					title={{
						icon,
						title,
					}}
					data={activeTab === "aggregation" && !emptyOrdersToAggregate ? ordersToAggregate : orders}
					columns={columns}
					fullPage
					isSinglePage
					paginationApi={this.pagination}
					filtrationApi={this.filtration}
					selectable
					renderExpanded={(item: any) => this.renderAggregationBagInfo(item)}
					filterable
					headerOptions={this.getTableHeaderOptions()}
					{...tableProps}
					actions={actions}
				/>
			);
		}

		if (
			activeTab === "loading" ||
			activeTab === "ready_to_van" ||
			activeTab === "dispatching" ||
			activeTab === "on_transit" ||
			activeTab === "delivered" ||
			activeTab === "not_delivered" ||
			activeTab === "cancelled" ||
			activeTab === "dispute"
		) {
			return this.renderLoadingTable();
		}

		return null;
	}

	renderLoadingTable() {
		const { routes, orders, activeTab, selectedBusiness, selectedContract, selectedDate } = this.state;

		const tableProps: any = {};

		if (activeTab !== "registered" && activeTab !== "address_confirmation") {
			if (!selectedBusiness && !selectedContract) {
				tableProps.emptyDescription = <span>{Strings.orders.pleaseSelectBoth}</span>;
			} else if (!selectedBusiness) {
				tableProps.emptyDescription = <span>{Strings.orders.pleaseSelectBusiness}</span>;
			} else if (!selectedContract) {
				tableProps.emptyDescription = <span>{Strings.orders.pleaseSelectContract}</span>;
			} else if (!selectedDate) {
				tableProps.emptyDescription = <span>{Strings.orders.pleaseSelectDate}</span>;
			}
		}

		const columns: Array<any> = this.getTableColumns();
		let actions: any = this.getTableActions();

		let title =
			activeTab === "delivered" ||
			activeTab === "not_delivered" ||
			activeTab === "cancelled" ||
			activeTab === "dispute"
				? Strings.sidebar.orders
				: Strings.orders.routes;

		let icon = "route";

		let expand =
			activeTab === "loading" ||
			activeTab === "dispatching" ||
			activeTab === "on_transit" ||
			activeTab === "delivered" ||
			activeTab === "not_delivered" ||
			activeTab === "cancelled" ||
			activeTab === "dispute";

		if (expand !== null && expand !== undefined) {
			tableProps.expanded = expand;
		}

		let isFromDeliverSteps =
			activeTab === "delivered" ||
			activeTab === "not_delivered" ||
			activeTab === "cancelled" ||
			activeTab === "dispute";

		console.log("🚀 ~ file: index.tsx:3700 ~ Orders ~ renderLoadingTable ~ pagination:", this.pagination);

		return (
			<Table
				title={{
					icon,
					title,
				}}
				data={isFromDeliverSteps ? orders : routes}
				columns={columns}
				fullPage
				isSinglePage
				paginationApi={this.pagination}
				selectable
				filterable
				noFieldFilter={activeTab !== "on_transit"}
				renderExpanded={(item: any) =>
					isFromDeliverSteps
						? this.renderBoxesOnTransit(item?.original?.orderFlow.boxes)
						: this.renderLoadingInfo(item)
				}
				headerOptions={this.getTableHeaderOptions()}
				{...tableProps}
				actions={actions}
			/>
		);
	}

	renderDrawer() {
		const {
			showDrawer,
			drawerType,
			activeTab,
			rowToChange,
			newBoxIdAggregation,
			selectedRouteGridId,
			selectedRouteVehicleId,
			selectedRouteDriverId,
			selectedRoute,
			isOrderInDeliverySteps,
			isReturnToPacking,
		} = this.state;
		const { mobile } = this.props;

		let drawerHeader = "";

		if (activeTab === "aggregation") {
			drawerHeader = Strings.orders.aggregation;
		} else if (activeTab === "packing") {
			drawerHeader = Strings.orders.packaging;
		} else if (activeTab === "loading") {
			drawerHeader = Strings.orders.selectGrid;
		} else if (activeTab === "ready_to_van") {
			drawerHeader = Strings.orders.selectVehicle;
		} else if (activeTab === "dispatching") {
			drawerHeader = Strings.orders.selectDriver;
		} else if (activeTab === "on_transit") {
			drawerHeader = isOrderInDeliverySteps ? Strings.orders.selectOrderStatus : Strings.orders.selectDriver;
		} else {
			drawerHeader = isOrderInDeliverySteps ? Strings.orders.selectOrderStatus : "";
		}

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="box" />
						<p>{drawerHeader}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							//disabled={isOrderInDeliverySteps && selectedOrderStatus === "delivered" && !orderDeliveredTime}
							onClick={
								//() => this.savePackaging()
								() => {
									if (activeTab === "loading") {
										this.selectRouteDetails(selectedRoute._id, selectedRouteGridId);
									} else if (activeTab === "ready_to_van") {
										this.selectRouteDetails(selectedRoute._id, selectedRouteVehicleId, "vehicle");
									} else if (activeTab === "dispatching") {
										this.selectRouteDetails(selectedRoute._id, selectedRouteDriverId, "driver");
									} else if (
										activeTab === "on_transit" ||
										activeTab === "delivered" ||
										activeTab === "not_delivered" ||
										activeTab === "cancelled" ||
										activeTab === "dispute"
									) {
										if (isOrderInDeliverySteps) {
											if (isReturnToPacking) {
												this.returnToPacking();
											} else {
												this.updateOrderStatus();
											}
										} else {
											this.selectRouteDetails(selectedRoute._id, selectedRouteDriverId, "driver");
										}
									} else if (activeTab === "aggregation") {
										this.handleConfirmOnAggregationDrawer(rowToChange, newBoxIdAggregation);
									} else {
										this.savePackaging();
									}
								}
							}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton SidebarFooterSingleButton --button-cancel"
							onClick={() => {
								this.setState({
									showDrawer: false,
									tempOrder: null,
									isOrderInDeliverySteps: false,
									isReturnToPacking: false,
								});
							}}
						>
							{Strings.generic.close}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() =>
					this.setState({
						tempOrder: null,
						showDrawer: false,
						isOrderInDeliverySteps: false,
						isReturnToPacking: false,
					})
				}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerAggregationContent() {
		return (
			<div>
				<label htmlFor="drawer_box_id" className="InputLabel">
					{Strings.orders.boxId}
				</label>
				<Input
					value={this.state.newBoxIdAggregation}
					placeholder="N/D"
					onChange={(localValue: any) => {
						this.setState({
							newBoxIdAggregation: localValue.target.value,
						});
					}}
				/>
			</div>
		);
	}

	renderDrawerFromTableContent(type = "loading") {
		const {
			grids,
			selectedRouteGrid,
			vehicles,
			selectedRouteVehicle,
			drivers,
			selectedRouteDriver,
			selectedRoute,
		} = this.state;

		const getLabelValue = () => {
			if (type === "loading") {
				return Strings.orders.grid;
			} else if (type === "ready_to_van") {
				return Strings.orders.vehicle;
			} else {
				return Strings.orders.driver;
			}
		};

		const getValue = () => {
			if (type === "loading") {
				return !!selectedRouteGrid ? selectedRouteGrid : "N/D";
			} else if (type === "ready_to_van") {
				return !!selectedRouteVehicle ? selectedRouteVehicle : "N/D";
			} else {
				return !!selectedRouteDriver ? selectedRouteDriver : "N/D";
			}
		};

		let vehicleType = selectedRoute?.name?.includes("Bike") ? "bike" : "van";

		let availableVehicles = vehicles.filter(
			(item: any) => item.capacity >= selectedRoute?.gridLoadSequence?.length,
		);

		let gridsAux = [...grids, { _id: null, name: "N/D" }];
		let vehiclesAux = [...availableVehicles, { _id: null, licensePlate: "N/D", type: "van" }];
		let driversAux = [...drivers, { _id: null, name: "N/D" }];

		const mapData = () => {
			if (type === "loading") {
				return gridsAux.map((grid: any) => {
					return <Select.Option value={grid._id}>{grid.name}</Select.Option>;
				});
			} else if (type === "ready_to_van") {
				return vehiclesAux.map((vehicle: any) => {
					if (vehicle?.type === vehicleType)
						return <Select.Option value={vehicle._id}>{vehicle.licensePlate}</Select.Option>;
				});
			} else {
				return driversAux.map((driver: any) => {
					return <Select.Option value={driver._id}>{driver.name}</Select.Option>;
				});
			}
		};

		return (
			<div>
				<label htmlFor="drawer_id" className="SelectLabel">
					{getLabelValue()}
				</label>
				<Select
					className="option_select"
					style={{ width: "100%" }}
					placeholder={getLabelValue()}
					showArrow={true}
					disabled={false}
					filterOption={(input: any, option: any) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					value={getValue()}
					id="drawer_selection"
					onChange={(elem: any) => {
						if (type === "loading") {
							let selectedGrid = gridsAux.find((grid: any) => grid._id === elem);
							this.setState({ selectedRouteGrid: selectedGrid.name, selectedRouteGridId: elem });
						} else if (type === "ready_to_van") {
							let selectedVehicle = vehiclesAux.find((vehicle: any) => vehicle._id === elem);
							this.setState({
								selectedRouteVehicle: selectedVehicle.licensePlate,
								selectedRouteVehicleId: elem,
							});
						} else {
							let selectedRouteDriver = driversAux.find((driver: any) => driver._id === elem);

							this.setState({
								selectedRouteDriver: selectedRouteDriver.name,
								selectedRouteDriverId: elem,
							});
						}
					}}
				>
					{mapData()}
				</Select>
			</div>
		);
	}

	renderOrderOnTransitDrawerContent() {
		const { orderDeliveredTime, activeTab, tempOrder, orderArrivalDate, selectedOrder } = this.state;

		console.log("🚀 ~ file: index.tsx:3793 ~ Orders ~ renderOrderOnTransitDrawerContent ~ tempOrder:", tempOrder);

		let statusList = ["delivered", "not_delivered", "cancelled", "dispute", "billing"];

		if (
			tempOrder?.orderFlow.status === "not_delivered" ||
			tempOrder?.orderFlow.status === "cancelled" ||
			tempOrder?.orderFlow.status === "dispute"
		) {
			statusList.unshift("packing");
		}

		return (
			<div>
				<label htmlFor="drawer_id" className="InputLabel --label-required">
					{Strings.orders.status}
				</label>
				<Select
					className="option_select"
					style={{ width: "100%" }}
					placeholder={Strings.orders.status}
					showArrow={true}
					disabled={false}
					filterOption={(input: any, option: any) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					value={this.getStatusName(tempOrder?.orderFlow?.status)}
					id="drawer_selection"
					onChange={(elem: any) => {
						this.setState((prevState: any) => ({
							tempOrder: {
								...prevState.tempOrder,
								orderFlow: {
									...prevState.orderFlow,
									status: elem,
								},
							},
							isReturnToPacking: elem === "packing",
						}));
					}}
				>
					{statusList.map((elem: any) => {
						return <Select.Option value={elem}>{this.getStatusName(elem)}</Select.Option>;
					})}
				</Select>
				{(tempOrder?.orderFlow?.status === "delivered" || tempOrder?.orderFlow?.status === "not_delivered") && (
					<>
						<div style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
							<label htmlFor="drawer_id" className="InputLabel">
								{Strings.orders.deliveredDate}
							</label>
							<DatePicker
								//minuteStep={30}
								value={
									tempOrder?.orderFlow?.status === "delivered"
										? moment(tempOrder.orderFlow.deliveredDate).tz("Europe/Lisbon")
										: moment(tempOrder.orderFlow.deliveryDate).tz("Europe/Lisbon")
								}
								onChange={(localValue: any) => {
									this.setState((prevState: any) => ({
										tempOrder:
											tempOrder?.orderFlow?.status === "delivered"
												? {
														...prevState.tempOrder,
														orderFlow: {
															...prevState.orderFlow,
															status: tempOrder?.orderFlow?.status,
															deliveredDate: moment.utc(localValue),
														},
												  }
												: {
														...prevState.tempOrder,
														orderFlow: {
															...prevState.orderFlow,
															status: tempOrder?.orderFlow?.status,
															deliveryDate: moment.utc(localValue),
															deliveredDate: null,
														},
												  },
									}));
								}}
								placeholder={Strings.orders.deliveredDate}
								format={"DD/MM/YYYY"}
								style={{ width: "50%" }}
							/>
						</div>
						{tempOrder?.orderFlow?.status === "delivered" && (
							<div style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
								<label htmlFor="drawer_id" className="InputLabel">
									{Strings.orders.deliveredTime}
								</label>
								<TimePicker
									//minuteStep={30}
									value={moment.utc(tempOrder.orderFlow.deliveredDate).tz("Europe/Lisbon")}
									onChange={(localValue: any) => {
										console.log(
											"🚀 ~ Orders ~ renderOrderOnTransitDrawerContent ~ localValue:",
											moment.utc(localValue),
										);
										const existingDate = this.state.tempOrder.orderFlow.deliveredDate;
										console.log(
											"🚀 ~ Orders ~ renderOrderOnTransitDrawerContent ~ existingDate:",
											existingDate,
										);
										const newDate = moment.utc(existingDate).set({
											hour: moment.utc(localValue).hour(),
											minute: moment.utc(localValue).minute(),
											second: moment.utc(localValue).second(),
										});
										this.setState((prevState: any) => ({
											tempOrder: {
												...prevState.tempOrder,
												orderFlow: {
													...prevState.orderFlow,
													status: "delivered",
													deliveredDate: newDate,
												},
											},
										}));
									}}
									placeholder={Strings.orders.deliveredTime}
									format={"HH:mm"}
									style={{ width: "50%" }}
								/>
							</div>
						)}
						<div style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
							<label htmlFor="drawer_id" className="InputLabel">
								{Strings.orders.arrivalTime}
							</label>
							<TimePicker
								//minuteStep={30}
								value={
									tempOrder.order_id?.arrivalTime ? moment.utc(tempOrder.order_id?.arrivalTime).tz("Europe/Lisbon") : null
								}
								onChange={(localValue: any) => {
									this.setState((prevState: any) => ({
										orderArrivalTime: localValue,
										tempOrder: {
											...prevState.tempOrder,
											arrivalTime: localValue,
											order_id: {
												...tempOrder.order_id,
												arrivalTime: moment.utc(localValue),
											},
										},
									}));
								}}
								placeholder={Strings.orders.arrivalTime}
								format={"HH:mm"}
								style={{ width: "50%" }}
							/>
						</div>
						<div style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
							<label htmlFor="drawer_id" className="InputLabel">
								{Strings.orders.departureTime}
							</label>
							<TimePicker
								//minuteStep={30}
								value={
									tempOrder.order_id?.departureTime
										? moment.utc(tempOrder.order_id?.departureTime).tz("Europe/Lisbon")
										: null
								}
								onChange={(localValue: any) => {
									this.setState((prevState: any) => ({
										orderDepartureTime: localValue,
										tempOrder: {
											...prevState.tempOrder,
											departureTime: localValue,
											order_id: {
												...tempOrder.order_id,
												departureTime: moment.utc(localValue),
											},
										},
									}));
								}}
								placeholder={Strings.orders.departureTime}
								format={"HH:mm"}
								style={{ width: "50%" }}
							/>
						</div>
					</>
				)}

				{tempOrder?.orderFlow?.status === "packing" && (
					<div style={{ marginTop: "10px" }}>
						<div>
							<label htmlFor="upload_modal_shift" className="InputLabel">
								{Strings.fields.shift}
							</label>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<TimePicker
									minuteStep={30}
									value={
										this.state.tempOrder ? moment.utc(this.state.tempOrder?.orderSlot?.from) : null
									}
									onChange={(localValue: any) => {
										//if (tempOrder?._id) {
										console.log(
											"🚀 ~ Orders ~ renderOrderOnTransitDrawerContent ~ tempOrder:",
											tempOrder,
										);
										this.setState((prevState: any) => ({
											tempOrder: {
												...prevState.tempOrder,
												orderSlot: {
													...tempOrder.orderSlot,
													from: moment.utc(localValue).valueOf(),
												},
											},
										}));
										//}
									}}
									placeholder={Strings.fields.shiftStart}
									format={"HH:mm"}
									style={{ marginRight: "10px", width: "50%" }}
								/>
								<TimePicker
									minuteStep={30}
									value={tempOrder ? moment.utc(tempOrder?.orderSlot?.to) : null}
									onChange={(localValue: any) => {
										//if (tempOrder?._id) {
										this.setState((prevState: any) => ({
											tempOrder: {
												...prevState.tempOrder,
												orderSlot: {
													...tempOrder.orderSlot,
													to: moment.utc(localValue).valueOf(),
												},
											},
										}));
										//}
									}}
									placeholder={Strings.fields.shiftEnd}
									format={"HH:mm"}
									style={{ width: "50%" }}
								/>
							</div>
						</div>
						<div style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
							<label htmlFor="drawer_id" className="InputLabel">
								{Strings.orders.deliveryDate}
							</label>
							<DatePicker
								value={moment.utc(tempOrder?.orderFlow?.deliveryDate)}
								onChange={(localValue: any) => {
									//if (tempOrder?._id) {
									this.setState((prevState: any) => ({
										tempOrder: {
											...prevState.tempOrder,
											orderFlow: {
												...prevState.tempOrder.orderFlow,
												deliveryDate: moment.utc(localValue),
											},
										},
									}));
									//}
								}}
								format={"DD/MM/YYYY"}
								style={{ width: "50%" }}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}

	renderDrawerContent() {
		const {
			tempOrder,
			packingType,
			defaultContracts,
			selectedContract,
			activeTab,
			drawerType,
			isOrderInDeliverySteps,
			multiplyBoxId,
			multiplyValue,
			multiplyWeight,
		} = this.state;
		const contractType = defaultContracts.find((cnt: any) => cnt._id === selectedContract)?.type?.type;

		let multiplyButtonDisabled =
			!multiplyBoxId || !multiplyValue || !multiplyWeight || multiplyWeight == "0" || multiplyValue == "0";

		if (activeTab === "loading") return this.renderDrawerFromTableContent();
		if (activeTab === "ready_to_van") return this.renderDrawerFromTableContent("ready_to_van");
		if (activeTab === "dispatching") return this.renderDrawerFromTableContent("dispatching");
		if (
			activeTab === "on_transit" ||
			activeTab === "delivered" ||
			activeTab === "not_delivered" ||
			activeTab === "cancelled" ||
			activeTab === "dispute"
		)
			return isOrderInDeliverySteps
				? this.renderOrderOnTransitDrawerContent()
				: this.renderDrawerFromTableContent("on_transit");
		if (activeTab === "aggregation") return this.renderDrawerAggregationContent();

		if (drawerType === "picking")
			return (
				<Row gutter={[20, 10]}>
					{contractType === "parcel" && (
						<Col xs={24}>
							<label htmlFor="drawer_packaging_ype" className="InputLabel">
								{Strings.orders.packagingType}
							</label>
							<Select
								className="address_select"
								style={{ width: "100%" }}
								placeholder={Strings.orders.packagingType}
								//showSearch
								showArrow={true}
								disabled={false}
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								value={packingType}
								id="drawer_packaging_ype"
								onChange={(elem: any) => {
									if (elem === "box") {
										if (
											!Array.isArray(tempOrder?.orderFlow?.boxes) ||
											tempOrder?.orderFlow?.boxes?.length < 1
										) {
											this.setState((prevState: any) => ({
												packingType: elem,
												tempOrder: {
													...prevState.tempOrder,
													orderFlow: {
														...prevState.orderFlow,
														boxes: [{ boxId: "", weight: null }],
													},
												},
											}));
										}
									}

									this.setState({
										packingType: elem,
									});
								}}
							>
								<Select.Option value="bag">{Strings.orders.bag}</Select.Option>
								<Select.Option value="box">{Strings.orders.box}</Select.Option>
							</Select>
						</Col>
					)}
					{packingType === "bag" ? (
						<React.Fragment>
							<Col xs={24}>
								<label htmlFor="drawer_bag_id" className="InputLabel">
									{Strings.orders.bagId}
								</label>
								<Input
									id="drawer_bag_id"
									placeholder={Strings.orders.bagId}
									value={tempOrder?.orderFlow?.bagId || ""}
									//readOnly
									onChange={(e: any) => {
										const value = e.target.value;
										this.setState((prevState: any) => ({
											tempOrder: {
												...prevState.tempOrder,
												orderFlow: {
													...prevState.tempOrder?.orderFlow,
													bagId: value,
												},
											},
										}));
									}}
								/>
							</Col>
							<Col xs={24}>
								<label htmlFor="drawer_bag_weight" className="InputLabel">
									{Strings.fields.weight}
								</label>
								<Input
									id="drawer_bag_weight"
									placeholder={Strings.fields.weight}
									value={tempOrder?.orderFlow?.bagWeight || ""}
									type="number"
									min={0}
									step="0.001"
									suffix="Kg"
									//readOnly
									onChange={(e: any) => {
										const value = e.target.value;
										this.setState((prevState: any) => ({
											tempOrder: {
												...prevState.tempOrder,
												orderFlow: {
													...prevState.tempOrder?.orderFlow,
													bagWeight: value,
												},
											},
										}));
									}}
								/>
							</Col>
						</React.Fragment>
					) : (
						<Col xs={24}>
							<div className="OrderDrawerBoxList">
								{tempOrder?.orderFlow?.boxes?.map((box: any, index: number) => (
									<div className="OrderDrawerBoxEntry" key={`drawer_box_${index}`}>
										<div className="OrderDrawerBoxTitle">
											<h3>
												{Strings.orders.box} #{index + 1}
											</h3>
											<button
												// disabled={tempOrder?.orderFlow?.boxes?.length <= 1}
												//disabled
												onClick={() => {
													tempOrder.orderFlow?.boxes.splice(index, 1);
													this.setState({ tempOrder });
												}}
											>
												<Icon name="trash" />
											</button>
										</div>
										<div className="OrderDrawerBoxRow">
											<label htmlFor={`drawer_box_id_${index}`} className="InputLabel">
												{Strings.orders.boxId}
											</label>
											<Input
												id={`drawer_box_id_${index}`}
												placeholder={Strings.orders.boxId}
												value={box.id ?? ""}
												//readOnly
												onChange={(e: any) => {
													box.id = e.target.value;
													this.setState({ tempOrder });
												}}
											/>
										</div>
										<div className="OrderDrawerBoxRow">
											<label htmlFor={`drawer_box_weight_${index}`} className="InputLabel">
												{Strings.orders.weight}
											</label>
											<Input
												id={`drawer_box_weight_${index}`}
												placeholder={Strings.fields.weight}
												value={box.weight ?? ""}
												type="number"
												min={0}
												step="0.001"
												suffix="Kg"
												//readOnly
												onChange={(e: any) => {
													box.weight = e.target.value;
													this.setState({ tempOrder });
												}}
											/>
										</div>
									</div>
								))}
							</div>
							{packingType === "box" && (
								<div className="OrderDrawerBoxAdd">
									<button
										onClick={() => {
											tempOrder?.orderFlow?.boxes?.push({ boxId: "", weight: null });
											this.setState({ tempOrder });
										}}
									>
										{Strings.orders.addBox}
									</button>
									{/* @ts-ignore */}
									<Divider></Divider>
									<div>
										<div
											style={{
												flexDirection: "row",
												marginTop: "10px",
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<div
												style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
											>
												<label className="InputLabel" style={{ width: "30%" }}>
													{Strings.orders.boxId}
												</label>
												<Input
													style={{ width: "70%" }}
													id={`drawer_multiply_box_id`}
													placeholder={Strings.orders.boxId}
													value={multiplyBoxId}
													//readOnly
													onChange={(e: any) => {
														this.setState({ multiplyBoxId: e.target.value });
													}}
												/>
											</div>

											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													marginLeft: "20px",
												}}
											>
												<label className="InputLabel">{Strings.orders.weight}</label>
												<Input
													id={`weight`}
													style={{ marginLeft: "10px", width: "80px" }}
													placeholder={"0"}
													value={this.state.multiplyWeight}
													type="number"
													min={0}
													step="0.001"
													suffix="Kg"
													//readOnly
													onChange={(e: any) => {
														this.setState({ multiplyWeight: e.target.value });
													}}
												/>
											</div>
										</div>
										<div
											style={{
												flexDirection: "row",
												marginTop: "20px",
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-end",
											}}
										>
											<label className="InputLabel">{"Qtd"}</label>
											<Input
												id={`qtd`}
												style={{ marginInline: "10px", width: "50px" }}
												placeholder={"0"}
												value={multiplyValue}
												type="number"
												min={0}
												step="1"
												//readOnly
												onChange={(e: any) => {
													this.setState({ multiplyValue: e.target.value });
												}}
											/>
											<button
												disabled={multiplyButtonDisabled}
												style={{ opacity: multiplyButtonDisabled ? 0.4 : 1 }}
												onClick={() => {
													for (let i = 0; i < multiplyValue; i++) {
														tempOrder?.orderFlow?.boxes?.push({
															id: multiplyBoxId,
															weight: multiplyWeight,
														});
														this.setState({ tempOrder });
													}
												}}
											>
												{"Multiplicar"}
											</button>
										</div>
									</div>
								</div>
							)}
						</Col>
					)}
				</Row>
			);

		return null;
	}

	downloadRouteCsv = (route: any) => {
		let auxAddresses = route.addresses;
		auxAddresses.shift();

		let content = auxAddresses.map((address: any) => ({
			orderId: address?.order_id?.orderId,
			client: address?.user?.name || "",
			address: address?.address,
			stopType: address?.address_stop_type?.toUpperCase(),
			eta: moment.utc(address?.estimatedTimeArrival).format("HH:mm") || "",
			predictedDate: moment.utc(address?.order_id?.orderFlows[0]?.deliveryDate).format("DD/MM/YYYY") || "",
			deliverySlot:
				moment.utc(address?.orderSlot?.from).format("HH:mm") +
				" - " +
				moment.utc(address?.orderSlot?.to).format("HH:mm"),
			deliveredDate:
				address?.order_id?.orderFlows[0]?.deliveredDate &&
				address?.order_id?.orderFlows[0]?.status === "delivered"
					? moment.utc(address?.order_id?.orderFlows[0]?.deliveredDate).tz("Europe/Lisbon").format("DD/MM/YYYY HH:mm")
					: "",
			boxes: address?.boxes?.map((item: any) => item.boxId).join(", "),
			arrivalTime: address?.order_id?.arrivalTime
				? moment.utc(address?.order_id?.arrivalTime).tz("Europe/Lisbon").format("HH:mm")
				: "",
			departureTime: address?.order_id?.arrivalTime
				? moment.utc(address?.order_id?.departureTime).tz("Europe/Lisbon").format("HH:mm")
				: "",
			routeName: route?.name,
		}));

		let data = [
			{
				sheet: "RouteOrders",
				columns: [
					{ label: "id", value: "id" },
					{ label: "Id Encomenda", value: "orderId" },
					{ label: "Cliente", value: "client" },
					{ label: "Morada", value: "address" },
					{ label: "Tipo Paragem", value: "stopType" },
					{ label: "ETA", value: "eta" },
					{ label: "Data Prevista", value: "predictedDate" },
					{ label: "Slot Entrega", value: "deliverySlot" },
					{ label: "Caixas", value: "boxes" },
					{ label: "Tempo Chegada", value: "arrivalTime" },
					{ label: "Tempo Saida", value: "departureTime" },
					{ label: "Nome Rota", value: "routeName" },
					{ label: "Data Entrega", value: "deliveredDate" },
				],
				content: content,
			},
		];

		let settings = {
			fileName: `${route.name}`,
			extraLength: 3,
			writeMode: "writeFile",
			writeOptions: {},
		};

		xlsx(data, settings);
	};

	render() {
		const { activeTab, defaultContracts, selectedContract } = this.state;
		const { dispatch, orderFilters } = this.props;

		console.log("ordersToAggregate", this.state.ordersToAggregate);
		const contract = defaultContracts.find((cnt: any) => cnt._id === selectedContract);

		return (
			<div className="ScreenOrders">
				<Helmet>
					<title>{Strings.sidebar.orders}</title>
					<meta name="description" content="Manage your orders and their status" />
				</Helmet>
				<ContentWrapper>
					<Tabs
						defaultActiveKey="registered"
						activeKey={activeTab}
						onChange={(key: string) => {
							dispatch(setOrderFilters({ ...orderFilters, tab: key }));

							let packingEntries: any = { type: "valid", orders: [] };
							if (key === "picking") {
								const contract = defaultContracts.find((cnt: any) => cnt._id === selectedContract);

								if (contract?.type?.type === "food" || contract?.type?.type === "pharmacy") {
									packingEntries = { type: "move_from_packing", orders: [] };
								} else {
									packingEntries = { type: "create_box", orders: [] };
								}
							}

							this.setState(
								(prevState: any) => ({
									activeTab: key,
									page: 0,
									globalSearch: "",
									orders: [],
									addressesEntries:
										key === "address_confirmation"
											? { type: "valid", orders: [] }
											: prevState.addressesEntries,
									packingEntries: key === "picking" ? packingEntries : prevState.packingEntries,
									preventCall: true,
								}),
								() => {
									this.getData();

									clearTimeout(this.preventRefresh);
									this.preventRefresh = setTimeout(() => this.setState({ preventCall: false }), 2000);
								},
							);
						}}
					>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="order" />
									<p>{Strings.orders.registered}</p>
								</div>
							}
							key="registered"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="order" />
									<p>{Strings.orders.addressConfirmation}</p>
								</div>
							}
							key="address_confirmation"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="package" />
									<p>{Strings.orders.picking}</p>
								</div>
							}
							key="picking"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="package" />
									<p>{Strings.orders.packing}</p>
								</div>
							}
							key="packing"
						/>
						{!!contract && contract?.type?.type === "parcel" && (
							<Tabs.TabPane
								tab={
									<div className="TabIconContainer">
										<Icon name="package" />
										<p>{Strings.orders.aggregation}</p>
									</div>
								}
								key="aggregation"
							/>
						)}
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="package" />
									<p>{Strings.orders.readyToLoad}</p>
								</div>
							}
							key="loading"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="delivery-truck" />
									<p>{Strings.orders.readyToVan}</p>
								</div>
							}
							key="ready_to_van"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="delivery-truck" />
									<p>{"Dispatching"}</p>
								</div>
							}
							key="dispatching"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="delivery-truck" />
									<p>{Strings.orders.on_transit}</p>
								</div>
							}
							key="on_transit"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="shipped" />
									<p>{Strings.orders.delivered}</p>
								</div>
							}
							key="delivered"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="delivery-truck" />
									<p>{Strings.orders.notDelivered}</p>
								</div>
							}
							key="not_delivered"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="order" />
									<p>{Strings.orders.cancelled}</p>
								</div>
							}
							key="cancelled"
						/>
						<Tabs.TabPane
							tab={
								<div className="TabIconContainer">
									<Icon name="refund" />
									<p>{Strings.orders.complaints}</p>
								</div>
							}
							key="dispute"
						/>
					</Tabs>
					<div className="OrdersTableContainer">{this.renderTable()}</div>
				</ContentWrapper>
				{activeTab === "packing" ? this.renderImportBoxesModal() : this.renderModal()}
				{this.renderInvalidOrdersModal()}
				{this.renderDrawer()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile,
	orderFilters: state.orderFilters,
});

export default connect(mapStateToProps)(Orders);
