// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginWrapper{display:flex;flex-direction:column;align-items:center;width:100%;height:100%;background-image:linear-gradient(180deg, #3277A3 1.04%, #143041 100%);background-position:center;background-size:cover;background-repeat:no-repeat;background-color:#fff;overflow:hidden auto}.LoginWrapper .LoginLogo{display:flex;flex-direction:column;align-items:center;margin:30px 0 0;font-size:28px}.LoginWrapper .LoginLogo img{width:78px;height:auto}.LoginWrapper .LoginLogo p{color:#fff;margin:10px 0 0;font-weight:600}.LoginWrapper .LoginContent{display:flex;flex-direction:column;justify-content:center;align-items:center;height:calc(100% - 150px);width:100%}.LoginWrapper .LoginContent .LoginWelcome{font-size:20px;font-weight:700;color:#fff}.LoginWrapper .LoginContent .LoginForgotPassword{font-size:12px;margin-top:5px;display:flex;justify-content:center;color:#fff}.LoginWrapper .LoginContent .isLink{cursor:pointer}.LoginWrapper .LoginContent .isLink:hover{color:#163683}.LoginWrapper .LoginContent .LoginButton{margin-top:30px;width:100%;background-color:#163683}.LoginWrapper .LoginContent form{margin-top:20px;width:350px}.LoginWrapper .LoginContent form em{color:#163683;font-weight:700}.LoginWrapper.__isMobile{padding:0 20px}.LoginWrapper.__isMobile .LoginLogo{margin-left:0}.LoginWrapper.__isMobile .LoginLogo,.LoginWrapper.__isMobile .LoginContent{margin-left:0}.LoginWrapper.__isMobile form{width:100%}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#075aae",
	"lightPrimary": "#3f5868",
	"secondaryColor": "#163683",
	"lightSecondary": "#b8e4f0",
	"darkPrimaryColor": "#103246"
};
module.exports = exports;
