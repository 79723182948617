/*
 *
 * EmailTemplates
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, delayedDispatch, setBreadcrumb, updateCrumb, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { Icon, Table } from "components";
import { Col, Drawer, Input, notification, Row, Select, Switch } from "antd";
import Strings from "utils/strings";

import { API, Endpoints } from "utils/api";

class ContractTypes extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			contractTypes: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences",
						},
						{
							text: Strings.settings.contractTypes,
							icon: "delivery-truck",
						},
					],
				};
			}),
		);

		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.get({
				url: Endpoints.uriContractTypes(),
			});

			if (response.ok) {
				const { contractTypes = [] } = response.data.results || {};
				this.setState({ contractTypes });
			} else {
				notification.error({
					message: Strings.settings.contractTypes,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.contractTypes,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async saveContractType() {
		const { tempContractType } = this.state;
		const { dispatch } = this.props;

		if (!tempContractType) return;

		if (!this.validContractType()) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			const request = tempContractType?._id ? API.put : API.post;
			response = await request({
				url: Endpoints.uriContractTypes(tempContractType?._id || ""),
				data: {
					name: tempContractType.name,
					type: tempContractType.type,
					url: tempContractType.url,
					isActive: tempContractType.isActive,
				},
			});

			if (response.ok) {
				await this.getData();
				this.setState({ tempContractType: null, showDrawer: false });

				notification.success({
					message: Strings.settings.contractTypes,
					description: Strings.contractTypes.contractUpdated,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.settings.contractTypes,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.contractTypes,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async patchContractType(contractType: any) {
		const { dispatch } = this.props;

		if (!contractType?._id) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.patch({
				url: Endpoints.uriContractTypes(contractType._id),
				data: {
					isActive: !contractType.isActive,
				},
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.settings.contractTypes,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.settings.contractTypes,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.contractTypes,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	async deleteContractType(contractType: any) {
		const { dispatch } = this.props;

		if (!contractType) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.delete({
				url: Endpoints.uriContractTypes(contractType),
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.settings.contractTypes,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				});
			} else {
				notification.error({
					message: Strings.settings.contractTypes,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.contractTypes,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			});
		}

		dispatch(setLoader(false));
	}

	validContractType() {
		const { tempContractType } = this.state;

		if (!tempContractType?.name) {
			notification.error({
				message: Strings.settings.contractTypes,
				description: Strings.settings.nameRequired,
				placement: "bottomRight",
				duration: 5,
			});
			return false;
		}

		if (!tempContractType?.type) {
			notification.error({
				message: Strings.settings.contractTypes,
				description: Strings.settings.typeRequired,
				placement: "bottomRight",
				duration: 5,
			});
			return false;
		}

		if (!tempContractType?.url) {
			notification.error({
				message: Strings.settings.contractTypes,
				description: Strings.settings.typeRequired,
				placement: "bottomRight",
				duration: 5,
			});
			return false;
		}

		return true;
	}

	getType(type: string) {
		switch (type) {
			case "food":
				return Strings.settings.food;
			case "parcel":
				return Strings.settings.parcel;
			case "pharmacy":
				return Strings.settings.pharmacy;
			default:
				return type;
		}
	}

	renderTable() {
		const { contractTypes = [] } = this.state;

		return (
			<Table
				title={{
					icon: "delivery-truck",
					title: Strings.settings.contractTypes,
				}}
				data={contractTypes}
				columns={[
					{
						Header: Strings.fields.name,
						id: "contract_type_name",
						accessor: (row: any) => row.name || "-",
					},
					{
						Header: Strings.settings.contractType,
						id: "contract_type_type",
						accessor: (row: any) => this.getType(row.type) || "-",
					},
					{
						Header: Strings.settings.contractUrl,
						id: "contract_type_url",
						accessor: (row: any) => row.url || "-",
					},
				]}
				fullPage
				isSinglePage
				filterable
				sortable
				add={{
					onClick: () => this.setState({ showDrawer: true }),
				}}
				actions={{
					edit: (row: any) => ({
						onClick: () =>
							this.setState({ showDrawer: true, tempContractType: JSON.parse(JSON.stringify(row)) }),
					}),
					remove: (row: any) => ({
						onClick: () => this.deleteContractType(row._id),
					}),
					toggle: (row: any) => ({
						onChange: () => this.patchContractType(row),
						value: row.isActive,
					}),
				}}
			/>
		);
	}

	renderDrawer() {
		const { showDrawer, tempContractType } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="delivery-truck" />
						<p>
							{tempContractType?._id
								? Strings.settings.editContractType
								: Strings.settings.addContractType}
						</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.saveContractType()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showDrawer: false,
									tempContractType: null,
								})
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() => this.setState({ tempContractType: null, showDrawer: false })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { tempContractType } = this.state;

		return (
			<Row gutter={[0, 10]}>
				<Col xs={24}>
					<label htmlFor="contract_type_name" className="InputLabel --label-required">
						{Strings.fields.contractTypeName}
					</label>
					<Input
						id="contract_type_name"
						placeholder={Strings.fields.contractTypeName}
						value={tempContractType?.name || ""}
						onChange={(e: any) => {
							const value = e.target.value;

							this.setState((prevState: any) => ({
								tempContractType: {
									...prevState.tempContractType,
									name: value,
								},
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="contract_type_type" className="InputLabel --label-required">
						{Strings.settings.contractType}
					</label>
					<Select
						id="contract_type_type"
						style={{ width: "100%" }}
						placeholder={Strings.settings.contractType}
						showSearch
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						value={tempContractType?.type || null}
						onChange={(value: any) => {
							this.setState((prevState: any) => ({
								tempContractType: {
									...prevState.tempContractType,
									type: value,
								},
							}));
						}}
					>
						<Select.Option value="food">{Strings.settings.food}</Select.Option>
						<Select.Option value="parcel">{Strings.settings.parcel}</Select.Option>
						<Select.Option value="pharmacy">{Strings.settings.pharmacy}</Select.Option>
					</Select>
				</Col>
				<Col xs={24}>
					<label htmlFor="contract_type_url" className="InputLabel --label-required">
						{Strings.fields.contractTypeUrl}
					</label>
					<Input
						id="contract_type_url"
						placeholder={Strings.fields.contractTypeUrl}
						value={tempContractType?.url || ""}
						onChange={(e: any) => {
							const value = e.target.value;

							this.setState((prevState: any) => ({
								tempContractType: {
									...prevState.tempContractType,
									url: value,
								},
							}));
						}}
					/>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.vehicles}</title>
					<meta name="description" content={Strings.settings.vehiclesDescription} />
				</Helmet>
				{this.renderTable()}
				{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile,
});

export default connect(mapStateToProps)(ContractTypes);
