// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ScreenOrder .OrderInputReadOnly{background-color:#f3f3f3}.ScreenOrder .OrderInputReadOnlyNotes{background-color:#f3f3f3;min-height:200px}.ScreenOrder .OrderErrors p{margin:0}.ScreenOrder .OrderErrors li{margin-left:20px}.ScreenOrder .ErrorEntry:not(:last-child){margin-bottom:10px}.ScreenOrder .ErrorTitle{color:#075aae;font-weight:500}.ImageGalleryImageContainer{display:flex;flex-direction:row;flex-wrap:wrap;gap:10px}.ImageGalleryImageContainer .ImageWrapper{position:relative;height:200px;width:200px}.ImageGalleryImageContainer .ImageGalleryOverlay{position:absolute;top:2px;right:2px}.ImageGalleryImageContainer .ImageGalleryOverlay .ImageGalleryOverlayOption{display:flex;justify-content:center;align-items:center;border-radius:50%;background-color:#d9d9d9;height:20px;width:20px;transition:all .3s ease-in-out;box-shadow:0 0 5px 0 rgba(0,0,0,.1);cursor:pointer}.ImageGalleryImageContainer .ImageGalleryOverlay .ImageGalleryOverlayOption em{font-size:12px;font-weight:700}.ImageGalleryImageContainer .ImageGalleryOverlay:hover{color:red}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#075aae",
	"lightPrimary": "#3f5868",
	"secondaryColor": "#163683",
	"lightSecondary": "#b8e4f0",
	"darkPrimaryColor": "#103246"
};
module.exports = exports;
