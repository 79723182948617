// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".h1{color:#075aae}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#075aae",
	"lightPrimary": "#3f5868",
	"secondaryColor": "#163683",
	"lightSecondary": "#b8e4f0",
	"darkPrimaryColor": "#103246"
};
module.exports = exports;
